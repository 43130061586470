import React, { useState, useEffect, useMemo, useRef } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-vote.scss";
import PointsInputWidget from "../PointsInputWidget";
import Button from "../basic/Button";
import { useLocal, useUser, useVote } from "../../hooks/useApi";
import Loader from "../basic/Loader";
import { useModal } from "../../hooks/useModal";
import {
  BUY_MODAL,
  CODE_MODAL,
  MAX_VOTING_POINTS,
  VOTED_SUCCESS_MODAL,
} from "../../constants";
import DevPre from "../dev/DevPre";
import cleanSongTitle from "../../utils/cleanSongTitle";

const VoteModal = ({ songid, songname, artist, cover, scid, radioid }) => {
  const { stackModal: openCodesModal } = useModal(CODE_MODAL);
  const { stackModal: openBuyModal } = useModal(BUY_MODAL);
  const { openModal } = useModal(VOTED_SUCCESS_MODAL);
  const modalRef = useRef();

  const [credits, setCredits] = useState(1);
  const { user, isKiosk } = useUser();
  const { points } = user;
  const { data: localData } = useLocal(radioid);

  const { mutation, vote } = useVote(radioid);

  const handleVote = () => {
    let availableCredits = 0;
    if (!isNaN(credits) && credits > 0) {
      availableCredits += credits;
    }
    if (
      localData &&
      !isNaN(localData.fcreditsuser) &&
      localData.fcreditsuser > 0
    ) {
      availableCredits += localData.fcreditsuser;
    }
    if (availableCredits > 0) {
      if (credits <= availableCredits) {
        vote({ songid: songid, votes: credits, scid: scid });
      } else {
        alert("no tienes suficientes créditos");
      }
    }
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      //alert(`Genial! le diste ${credits} puntos a ${songname}`);
      openModal(
        { credits: credits, songname: songname, radioid: radioid },
        5000
      );
      console.log("open modal", modalRef.current);
    }
  }, [mutation, credits, songname, openModal]);

  const errorMessage = mutation.isError && mutation.error.response.data.msg;

  const cleanSongname = useMemo(
    () => cleanSongTitle(songname, artist),
    [songname, artist]
  );

  const availableCredits = useMemo(() => {
    let availableCredits = 0;
    const intValPoints = parseInt(points);
    if (!isNaN(intValPoints) && intValPoints > 0) {
      availableCredits += intValPoints;
    }
    // if (
    //   localData &&
    //   !isNaN(localData.fcreditsuser) &&
    //   localData.fcreditsuser > 0
    // ) {
    //   availableCredits += localData.fcreditsuser;
    // }
    return availableCredits;
  }, [points]); // , localData]);

  const showPointsInputWidget = useMemo(() => {
    return localData.name.indexOf("El Corral") === -1;
  }, [localData?.name]);

  return (
    <Modal className="modal-vote" ref={(r) => (modalRef.current = r)}>
      <DevPre>
        {typeof radioid} - {radioid}
      </DevPre>
      <div className="song modal-song-info">
        {cover && cover !== "" ? (
          <img src={cover} alt={songname} className="cover" />
        ) : null}
        <div className="songname">{cleanSongname}</div>
        <div className="artist">{artist}</div>
      </div>
      {showPointsInputWidget ? (
        <PointsInputWidget
          value={credits}
          onChange={setCredits}
          max={Math.min(MAX_VOTING_POINTS, points)}
        />
      ) : null}
      <Button
        size="big"
        className="accent"
        onClick={handleVote}
        disabled={mutation.isLoading || availableCredits < credits}
      >
        {mutation.isLoading ? (
          <Loader />
        ) : showPointsInputWidget ? (
          `Votar`
        ) : (
          `Reproducir canción`
        )}
      </Button>
      {errorMessage ? <p className="error">{errorMessage}</p> : null}
      {availableCredits < credits && (
        <>
          <p className="credits-message">
            <strong>{`${availableCredits === 0 ? "No tienes" : points} credito${
              availableCredits === 1 ? "" : "s"
            }`}</strong>{" "}
            {`disponible${availableCredits === 1 ? "" : "s"}`}
            {!isKiosk && localData ? (
              <>
                <br />
                <span>
                  {localData.fcreditsuser > 0
                    ? `Tienes ${localData.fcreditsuser} crédito${
                        localData.fcreditsuser === 1 ? "" : "s"
                      } gratis en ${localData.name}`
                    : `No tienes créditos gratis en ${localData.name}`}
                </span>
              </>
            ) : null}
          </p>

          {!isKiosk ? (
            <Button
              size="big"
              className="bordered brand"
              onClick={openBuyModal}
            >
              Comprar créditos
            </Button>
          ) : null}
          <Button
            size="big"
            className="bordered brand"
            onClick={openCodesModal}
          >
            Ingresar código
          </Button>
        </>
      )}
      <DevPre>{JSON.stringify(localData, null, " ")}</DevPre>
    </Modal>
  );
};

export default VoteModal;
