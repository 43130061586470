import React from "react";

import Button from "../../../basic/Button";

import AppleBtn from "../../../../images/download_appstore_btn-black.svg";
import AndroidBtn from "../../../../images/download_playstore_btn-black.svg";
import {
  ANDROID_STORE_URL,
  IOS_STORE_URL,
  PLATFORM_ANDROID,
  PLATFORM_APPLE,
} from "../../../../constants";

const AppStoreButton = ({ platform, style, className }) => {
  const handleOnClick = () => {
    switch (platform) {
      case PLATFORM_APPLE:
        window.location.href = IOS_STORE_URL;
        break;
      case PLATFORM_ANDROID:
        window.location.href = ANDROID_STORE_URL;
        break;
      default:
        break;
    }
  };

  if (platform !== PLATFORM_ANDROID && platform !== PLATFORM_APPLE) {
    return null;
  }
  return (
    <Button
      as="transparent"
      onClick={handleOnClick}
      style={{ borderRadius: 0, ...style }}
      className={["app-store-btn", className].join(" ")}
    >
      {platform === PLATFORM_APPLE ? (
        <img src={AppleBtn} alt="Consíguelo en el AppStore" height={54} />
      ) : platform === PLATFORM_ANDROID ? (
        <img src={AndroidBtn} alt="Disponible en Google Play" height={54} />
      ) : null}
    </Button>
  );
};

export default AppStoreButton;
