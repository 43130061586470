import TaskFormFaceRadio from "./TaskFormFaceRadio";
import TaskFormQuestionGroup from "./TaskFormQuestionGroup";
import TaskFormRadio from "./TaskFormRadio";

export default function TaskFormInput({
  field,
  value,
  handleOnChange,
  touched,
  error,
}) {
  return (
    <div className="task-form-input-container">
      <label className="task-form-label">{field.label}</label>
      <Input
        field={field}
        value={value}
        handleOnChange={handleOnChange}
        touched={touched}
        error={error}
      />
      {touched && error && <div className="task-form-error">{error}</div>}
    </div>
  );
}

const Input = ({ field, value, handleOnChange, touched, error }) => {
  switch (field.type) {
    case "radio":
      return (
        <div
          className={[
            "task-form-radiogroup",
            error ? "task-form-w-error" : "",
          ].join(" ")}
        >
          {field.options.map((option, index) => (
            <TaskFormRadio
              key={`${field.name}-${index}`}
              {...option}
              name={field.name}
              onChange={handleOnChange}
              checked={value === option.value}
              touched={
                typeof touched[field.name] !== "undefined" &&
                touched[field.name]
              }
            />
          ))}
        </div>
      );
    case "textarea":
      return (
        <textarea
          className={[
            "task-form-textarea",
            error ? "task-form-w-error" : "",
          ].join(" ")}
          name={field.name}
          value={value}
          onChange={(event) => {
            handleOnChange(field.name, event.target.value);
          }}
          {...field}
        />
      );
    case "faces":
      const rangeArray = Array.from({ length: 10 }, (_, i) => i + 1);
      return (
        <div
          className={[
            "task-form-radiogroup",
            error ? "task-form-w-error" : "",
          ].join(" ")}
        >
          {rangeArray.map((faceValue, index) => (
            <TaskFormFaceRadio
              key={`${field.name}-${index}`}
              name={field.name}
              faceStyle={field.facesstyle}
              value={index + field.range[0]}
              onChange={handleOnChange}
              checked={value === index + field.range[0]}
              touched={
                typeof touched[field.name] !== "undefined" &&
                touched[field.name]
              }
            />
          ))}
        </div>
      );
    case "questiongroup":
      return (
        <TaskFormQuestionGroup
          {...field}
          value={value}
          onChange={(newValues) => {
            handleOnChange(field.name, newValues);
          }}
          touched={
            typeof touched[field.name] !== "undefined" && touched[field.name]
          }
        />
      );
    default:
      return (
        <input
          className={`task-form-input task-form-${field.type}`}
          name={field.name}
          value={value}
          onChange={(event) => {
            handleOnChange(field.name, event.target.value);
          }}
          {...field}
        />
      );
  }
};
