export const VOTE_MODAL = "vote";
export const BUY_MODAL = "buy";
export const CODE_MODAL = "code";
export const ADD_CREDITS_MODAL = "add-credits";
export const ADD_CREDITS_SUCCESS_MODAL = "add-credits-success";
export const VOTED_SUCCESS_MODAL = "voted-success";
export const CREDIT_CARD_MODAL = "credit-card";
export const ADD_SONG_TO_RADIO_MODAL = "add-song-radio-modal";
export const DOWNLOAD_APP_MODAL = "download-app-modal";
export const DOWNLOAD_APP_REDEEM_MODAL = "download-app-redeem-modal";
export const TERMS_AND_CONDITIONS_MODAL = "terms-and-conditions-modal";

export const PROFILE_SECTION_ORDERS = "orders";
export const PROFILE_SECTION_PROFILE = "";
export const PROFILE_SECTION_FAVORITES = "favs";

export const IDLE_WAITING_TIME = 25000;

export const MAX_VOTING_POINTS = 3;

export const PLATFORM_APPLE = "iOS";
export const PLATFORM_ANDROID = "Android";

export const FACEBOOK_APP_ID = "451636488878156";
export const GOOGLE_CLIENT_ID =
  "888560076929-57lj3lo26rc1b6phn0c3du9oi17odh16.apps.googleusercontent.com";

export const IOS_STORE_URL =
  "https://apps.apple.com/uy/app/sonicbox/id1499419036";
export const ANDROID_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.kicknerds.sonicbox";

export const KIOSK_CODE_LENGTH = 4;
