import "../styles/task-form.scss";
import Face from "./Face";

export default function TaskFormFaceRadio({
  value,
  name,
  checked,
  onChange,
  faceStyle,
}) {
  return (
    <div className="task-form-radio">
      <label className="task-form-radio-label">
        <input
          type="radio"
          className="task-form-radio-input"
          name={name}
          value={value}
          onChange={(event) => {
            onChange(name, value);
            console.log(event.target.value);
          }}
          style={{opacity: 0, position: "absolute", visibility: "hidden"}}
        />
        <Face
          value={value}
          faceStyle={faceStyle}
          style={{ width: 40, height: 40 }}
          checked={checked}
        />
      </label>
    </div>
  );
}
