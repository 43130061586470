import "../styles/task-header.scss";

export default function TaskHeader({ name, radioimage, large }) {
  return (
    (name && (
      <div
        className={[
          "task-header",
          large ? "task-header-big" : "task-header-small",
        ].join(" ")}
      >
        <h1>{name}</h1>
        <div className="task-header-row">
          <img src={radioimage} alt={name} className={"radio-image"} />
          <h1>{name}</h1>
        </div>
      </div>
    )) ||
    null
  );
}
