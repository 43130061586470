import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";

import { isMobile } from "react-device-detect";

import "../styles/modal.scss";
import { useUser } from "./useApi";
import { DOWNLOAD_APP_MODAL, TERMS_AND_CONDITIONS_MODAL } from "../constants";

const ModalContext = createContext({
  currentModalId: null,
  setCurrentModalId: (modalId) => {},
  params: null,
  setParams: (modalParams) => {},
  setStack: (newStack) => {},
  stack: [],
  openModal: (modalId, modalParams) => {},
  closeModal: () => {},
  stackModal: (modalId, modalParams) => {},
  lastModal: null,
});

export const ModalsProvider = (props) => {
  const lastModalRef = useRef(null);
  const [lastModal, setLastModal] = useState(null);
  const [stack, setStack] = useState([]);
  const [currentModalId, setCurrentModalId] = useState(null);
  const [params, setParams] = useState(null);
  const [downloadAppModalShown, setDownloadAppModalShown] = useState(false);
  const { isKiosk, logged } = useUser();

  useEffect(() => {
    if (stack.length === 0) {
      setCurrentModalId(null);
    } else {
      setCurrentModalId(stack[stack.length - 1].id);
      setParams(stack[stack.length - 1].params);
    }
  }, [stack]);

  useEffect(() => {
    setLastModal(lastModalRef.current);
    lastModalRef.current = currentModalId;
  }, [currentModalId]);

  const activeModal = useMemo(() => {
    if (stack.length > 0) {
      const lastInStack = stack[stack.length - 1];
      if (
        props.modals.hasOwnProperty(lastInStack.id) &&
        typeof props.modals[lastInStack.id] === "function"
      ) {
        return props.modals[lastInStack.id](lastInStack.params);
      }
    }
    if (currentModalId !== null) {
      if (
        props.modals.hasOwnProperty(currentModalId) &&
        typeof props.modals[currentModalId] === "function"
      ) {
        return props.modals[currentModalId](params);
      } else {
        console.warn(`${currentModalId} not found in [modals]`);
      }
    }
    return null;
  }, [props.modals, currentModalId, params, stack]);

  const closeModal = useCallback(() => {
    if (params?.onClose) {
      params.onClose();
    }
    if (stack.length > 0) {
      const newStack = [...stack];
      newStack.pop();
      setStack(newStack);
    } else {
      setCurrentModalId(null);
      setParams(null);
    }
  }, [params, stack]);

  const openModal = useCallback(
    (modalId, newParams, closeTimeout = null, timeoutCallback = () => {}) => {
      setStack([{ id: modalId, params: newParams }]);
      setCurrentModalId(modalId);
      setParams(newParams);
      const startTime = new Date().getTime();
      console.log(modalId, "setTimeout", closeTimeout, new Date().getTime());
      if (closeTimeout) {
        setTimeout(function () {
          console.log("close setTimeout", new Date().getTime() - startTime);
          closeModal();
          timeoutCallback();
        }, closeTimeout);
      }
    },
    [closeModal]
  );

  const stackModal = (modalId, newParams) => {
    setStack([...stack, { id: modalId, params: newParams }]);
    setCurrentModalId(null);
    setParams(null);
  };

  useEffect(() => {
    if (!isKiosk) {
      console.log("home useEffect currentModal", currentModalId);
      if (
        logged &&
        isMobile &&
        currentModalId !== DOWNLOAD_APP_MODAL &&
        currentModalId !== TERMS_AND_CONDITIONS_MODAL &&
        !downloadAppModalShown
      ) {
        setTimeout(() => {
          openModal(DOWNLOAD_APP_MODAL);
        }, 2000);
        setDownloadAppModalShown(true);
      }
    }
  }, [
    logged,
    isKiosk,
    currentModalId,
    openModal,
    downloadAppModalShown,
    setDownloadAppModalShown,
  ]);

  return (
    <ModalContext.Provider
      value={{
        currentModalId: currentModalId,
        setCurrentModalId: setCurrentModalId,
        params: params,
        setParams: setParams,
        setStack: setStack,
        stack: stack,
        openModal: openModal,
        closeModal: closeModal,
        stackModal: stackModal,
        lastModal: lastModal,
      }}
    >
      {props.children}
      {activeModal !== null ? activeModal : null}
    </ModalContext.Provider>
  );
};

export const useModal = (modalId) => {
  const {
    currentModalId,
    params,
    stack,
    openModal,
    closeModal,
    stackModal,
    lastModal,
  } = useContext(ModalContext);

  return {
    params: params,
    openModal: (newParams, closeTimeout = null, timeoutCallback = () => {}) =>
      openModal(modalId, newParams, closeTimeout, timeoutCallback),
    closeModal: closeModal,
    currentModal: currentModalId,
    stackModal: (newParams) => stackModal(modalId, newParams),
    stack: stack,
    lastModal: lastModal,
  };
};
