import FaceLine1 from "../../../../images/faces/face-line-1.png";
import FaceLine2 from "../../../../images/faces/face-line-2.png";
import FaceLine3 from "../../../../images/faces/face-line-3.png";
import FaceLine4 from "../../../../images/faces/face-line-4.png";
import FaceLine5 from "../../../../images/faces/face-line-5.png";
import FaceLine6 from "../../../../images/faces/face-line-6.png";
import FaceLine7 from "../../../../images/faces/face-line-7.png";
import FaceLine8 from "../../../../images/faces/face-line-8.png";
import FaceLine9 from "../../../../images/faces/face-line-9.png";
import FaceLine10 from "../../../../images/faces/face-line-10.png";
import FaceSolid1 from "../../../../images/faces/face-solid-1.png";
import FaceSolid2 from "../../../../images/faces/face-solid-2.png";
import FaceSolid3 from "../../../../images/faces/face-solid-3.png";
import FaceSolid4 from "../../../../images/faces/face-solid-4.png";
import FaceSolid5 from "../../../../images/faces/face-solid-5.png";
import FaceSolid6 from "../../../../images/faces/face-solid-6.png";
import FaceSolid7 from "../../../../images/faces/face-solid-7.png";
import FaceSolid8 from "../../../../images/faces/face-solid-8.png";
import FaceSolid9 from "../../../../images/faces/face-solid-9.png";
import FaceSolid10 from "../../../../images/faces/face-solid-10.png";
import { useMemo } from "react";

const images = {
  solid: {
    1: FaceSolid1,
    2: FaceSolid2,
    3: FaceSolid3,
    4: FaceSolid4,
    5: FaceSolid5,
    6: FaceSolid6,
    7: FaceSolid7,
    8: FaceSolid8,
    9: FaceSolid9,
    10: FaceSolid10,
  },
  line: {
    1: FaceLine1,
    2: FaceLine2,
    3: FaceLine3,
    4: FaceLine4,
    5: FaceLine5,
    6: FaceLine6,
    7: FaceLine7,
    8: FaceLine8,
    9: FaceLine9,
    10: FaceLine10,
  },
};

export default function Face({ value, faceStyle, checked, style = {} }) {
  const _faceStyle = useMemo(() => {
    if (checked) {
      return faceStyle === "line" ? "solid" : "line";
    } else {
      return faceStyle;
    }
  }, [faceStyle, checked]);

  const image = useMemo(
    () =>
      typeof images[_faceStyle] !== "undefined" && images[_faceStyle][value],
    [_faceStyle, value]
  );

  return (
    <img src={image} alt={value} style={style} className="task-form-face" />
  );
}
