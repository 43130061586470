import styles from "../styles/task-questiongroup.scss";

export default function TaskFormQuestionGroup({
  questions,
  value,
  onChange,
  touched,
}) {
  const handleOnChange = (questionIndex, optionSelected) => {
    onChange({
      ...value,
      [questionIndex]: optionSelected,
    });
  };

  return (
    <div className="task-questiongroup">
      <table>
        <tbody>
          {questions.map((question, i) => (
            <tr key={i}>
              <td className="task-questiongroup-question">{question.label}</td>
              {question.options.map((option) => (
                <td
                  key={`${question.name}-${option}`}
                  className={[
                    "task-questiongroup-radio",
                    value && option === value[question.name] ? "selected" : "",
                  ].join(" ")}
                >
                  <label htmlFor={`${question.name}-${option}`}>
                    <input
                      id={`${question.name}-${option}`}
                      type="radio"
                      name={question.name}
                      value={option ? option : ""}
                      checked={value && option === value[question.name]}
                      onChange={() => handleOnChange(question.name, option)}
                    />
                    <span>{option}</span>
                  </label>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
