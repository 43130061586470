import React, { useMemo } from "react";
import { useDisplayRadio, useUser } from "../../../../hooks/useApi";
import Loader from "../../../basic/Loader";
import DevPre from "../../../dev/DevPre";
import OnoffSVG from "../../../../images/onoff.svg";
import Button from "../../../basic/Button";
import "../styles/profile.scss";

const ProfileHeader = () => {
  const { userQuery, user, logged, logout, isKiosk } = useUser();

  const { name, avatar } =
    user !== null
      ? user
      : {
          name: "",
          email: "",
          avatar: null,
        };

  const firstLetters = useMemo(() => {
    if (typeof name === "string") {
      const split = name.split(" ");
      return split.reduce((prev, current) => `${prev}${current[0]}`, "");
    }
    return "";
  }, [name]);

  const handleLogout = () => {
    if (isKiosk) {
      if (window.prompt("Ingrese la contraseña") === "yoda") {
        logout();
      }
    } else {
      logout();
    }
  };

  const { isDisplay } = useDisplayRadio();

  return (
    <div className="profile-header">
      {userQuery.isLoading ? (
        <Loader />
      ) : userQuery.isError ? (
        <p style={{ color: "red" }}>{userQuery.error}</p>
      ) : logged ? (
        <>
          <div className="avatar-row">
            <div className="avatar-container">
              {avatar ? (
                <img src={avatar} alt={name} />
              ) : (
                <span>{firstLetters}</span>
              )}
            </div>
            {!isDisplay ? (
              <Button onClick={handleLogout} className="logout-button">
                <img src={OnoffSVG} alt="Salir" />
                <span>Salir</span>
              </Button>
            ) : null}
          </div>
          <h2>{user.name}</h2>
          <p>
            <small>{user.email}</small>
          </p>
        </>
      ) : (
        <DevPre style={{ color: "lime" }}>
          {JSON.stringify(user, null, " ")}
        </DevPre>
      )}
    </div>
  );
};
export default ProfileHeader;
