import { useDisplayRadio } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";
import "./styles/tasks.scss";
import Task from "./Task";
import { TasksProvider } from "../../../hooks/useTasks";
import { useEffect } from "react";

export default function TaskContainer({ nameseo }) {
  useEffect(() => {
    window.location.href = `https://millsonicbox.com/task/${nameseo}`;
  }, [nameseo]);
  const { getRadioQuery } = useDisplayRadio();
  const { data: radio, isLoading } = getRadioQuery;

  return (
    <TasksProvider>
      <div className="tasks-container">
        {(isLoading && <Loader />) || <Task {...radio} />}
      </div>
    </TasksProvider>
  );
}
