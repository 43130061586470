import React, { useState, useEffect } from "react";
import { CREDIT_CARD_MODAL } from "../constants";
import { useCards } from "../hooks/useApi";
import { useModal } from "../hooks/useModal";
import Button from "./basic/Button";
import CreditCard from "./CreditCard";
import DevPre from "./dev/DevPre";
import Loader from "./basic/Loader";

const CreditCards = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const { cards, isLoading, isError, error } = useCards();

  const { stackModal, currentModal } = useModal(CREDIT_CARD_MODAL);

  useEffect(() => {
    if (
      currentModal !== CREDIT_CARD_MODAL &&
      Array.isArray(cards) &&
      cards.length === 0
    ) {
      stackModal({});
    }
  }, [currentModal, stackModal, cards, isLoading, isError]);

  const handleNewCard = (values) => {
    stackModal({});
  };

  const handleSelectCard = (card) => {
    setSelectedCard(card);
  };

  return (
    <div className="credit-cards">
      <h3>Elige el método de pago</h3>
      <DevPre>{JSON.stringify(cards, null, " ")}</DevPre>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <DevPre>{JSON.stringify(error, null, " ")}</DevPre>
      ) : cards.length === 0 ? (
        <>
          <p>No tienes tarjetas asociadas aún.</p>
          <Button className="bordered accent" onClick={handleNewCard}>
            Agrega una tarjeta
          </Button>
        </>
      ) : (
        <>
          <div className="cards-list">
            {cards.map((card, index) => (
              <CreditCard
                {...card}
                key={card.ucid}
                onClick={() => handleSelectCard(card)}
                selected={
                  selectedCard !== null && selectedCard.ucid === card.ucid
                }
              />
            ))}
          </div>
          <Button className="bordered accent" onClick={() => stackModal({})}>
            Cambiar método de pago
          </Button>
        </>
      )}
    </div>
  );
};

export default CreditCards;
