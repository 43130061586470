import React, { useMemo, useCallback, forwardRef } from "react";
import { BoxParent, Playlist } from "./Box";
import "../styles/boxes.scss";

const Boxes = forwardRef(
  (
    {
      parents,
      playlists,
      onSelectBox,
      selectedBox,
      boxesRef,
      songsRef,
      boxesOffset,
      songsOffset,
      selectedParent,
      setParent,
      isKiosk = false,
    },
    containerRef
  ) => {
    // const containerRef = useRef()

    const handleSelectParent = useCallback(
      (singleParent) => {
        setParent(
          selectedParent === null ||
            singleParent.parentId !== selectedParent.parentId
            ? singleParent
            : null
        );
      },
      [selectedParent, setParent]
    );

    const filteredParents = useMemo(() => {
      if (parents && !isKiosk) {
        return parents
          .filter((singleParent) => singleParent.playlists.length > 0)
          .map((singleParent) => {
            const playlistsArr = JSON.parse(singleParent.playlists);
            const playlistsObjects = playlists.filter(
              (playlist) =>
                playlistsArr.includes(playlist.playlistid.toString()) ||
                playlistsArr.includes(parseInt(playlist.playlistid))
            );
            return { ...singleParent, playlistsObjects: playlistsObjects };
          });
      }
      return [];
    }, [parents, playlists, isKiosk]);

    const filteredPlaylists = useMemo(() => {
      if (isKiosk || parents.length === 0) {
        return playlists;
      } else {
        if (playlists !== null && selectedParent !== null) {
          return selectedParent.playlistsObjects;
        }
      }
      return [];
    }, [selectedParent, playlists, isKiosk, parents]);

    const isParentSelected = selectedParent !== null;

    const { containerStyle: boxesContainerStyle } = getGridSize(
      filteredPlaylists,
      containerRef
    );

    const { containerStyle: parentsContainerStyle } = getGridSize(
      filteredParents,
      containerRef
    );

    if (isParentSelected) {
      parentsContainerStyle.height = "inherit";
    }

    return (
      <>
        {!isKiosk ? (
          <div
            className={[
              "boxes",
              "parents",
              isParentSelected ? "is-header" : "",
              `count-${filteredPlaylists.length}`,
            ].join(" ")}
            ref={containerRef}
          >
            <div className="boxes-container" style={parentsContainerStyle}>
              {filteredParents.map((singleParent, index) => {
                const isCurrentHidden =
                  isParentSelected &&
                  singleParent.parentId !== selectedParent.parentId;
                const isCurrentSelected =
                  isParentSelected &&
                  singleParent.parentId === selectedParent.parentId;
                return (
                  <BoxParent
                    key={`parent-${singleParent.parentId}`}
                    isHeading={isCurrentSelected}
                    isHidden={isCurrentHidden}
                    {...singleParent}
                    onClick={() => handleSelectParent(singleParent)}
                    onSelectBox={onSelectBox}
                    targetRef={boxesRef}
                    targetOffset={boxesOffset}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
        <div
          className={[
            "boxes",
            "playlists",
            `count-${filteredPlaylists.length}`,
            filteredPlaylists.length > 0 ? "with-boxes" : "",
          ].join(" ")}
        >
          <div className="boxes-container" style={boxesContainerStyle}>
            {filteredPlaylists.map((playlist, index) => (
              <Playlist
                key={`playlist-${index}`}
                {...playlist}
                onSelectBox={onSelectBox}
                isSelected={playlist.playlistid === selectedBox}
                targetRef={songsRef}
                targetOffset={songsOffset}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
);

export default Boxes;

const BOX_HEIGHT = 120;
const BOX_WIDTH = 80;
const BOX_OFFSET_WIDTH = BOX_WIDTH + 16;
const BOX_OFFSET_HEIGHT = BOX_HEIGHT + 16;

function getGridSize(boxes, containerRef) {
  if (boxes) {
    let boxesHeightCount = boxes.length > 6 ? 3 : boxes.length > 3 ? 2 : 1;

    let containerWidth = "wide";

    if (containerRef.current !== undefined && containerRef.current !== null) {
      if (containerRef.current.offsetWidth < 500) {
        containerWidth = containerRef.current.offsetWidth;
        boxesHeightCount = boxes.length > 4 ? 3 : boxes.length > 3 ? 2 : 1;
      }
    }
    const boxesWidthCount = Math.ceil(boxes.length / boxesHeightCount);
    const boxesHeight = BOX_OFFSET_WIDTH * boxesHeightCount + 32;
    const boxesWidth = boxesWidthCount * BOX_OFFSET_HEIGHT + 32;

    const containerStyle = {
      // width: boxesWidth,
      // height: boxesHeight,
      // paddingRight:
      //   containerWidth === "wide"
      //     ? "1rem"
      //     : `${16 + containerWidth * 0.5 - boxesWidthCount * BOX_OFFSET_WIDTH}`,
      // paddingLeft:
      //   containerWidth === "wide"
      //     ? "1rem"
      //     : `${16 + containerWidth * 0.5 - boxesWidthCount * BOX_OFFSET_WIDTH}`,
    };

    return {
      height: boxesHeight,
      width: boxesWidth,
      containerWidth: containerWidth,
      containerStyle: containerStyle,
    };
  }

  return {
    height: 0,
    width: 0,
    containerWidth: 0,
    containerStyle: {},
  };
}
