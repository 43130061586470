import React from "react";
import { Router } from "@reach/router";
import { ReactQueryDevtools } from "react-query/devtools";

import Layout from "../components/layout/Layout";
import PublicRoute from "../components/PublicRoute";
import PrivateRoute from "../components/PrivateRoute";
import Login from "../components/screens/login";
import Home from "../components/screens/home";
import Seo from "../components/seo";
import Sonicboxes from "../components/screens/sonicboxes";
import Sonicbox from "../components/screens/sonicbox";
import Account from "../components/screens/account";
import { apiQueryClient } from "../hooks/useApi";
import {
  PROFILE_SECTION_FAVORITES,
  PROFILE_SECTION_ORDERS,
  PROFILE_SECTION_PROFILE,
} from "../constants";
import Orders from "../components/screens/account/Orders";
import Profile from "../components/screens/account/Profile";
import Favorites from "../components/screens/account/Favorites";
import Kiosk from "../components/screens/kiosk";
import versionUpdater from "../services/versionUpdater";
import Redeem from "../components/screens/redeem";
import Task from "../components/screens/tasks";
import Display from "../components/screens/display";
import KioskWithCode from "../components/screens/kiosk/KioskWithCode";

versionUpdater();

const App = () => (
  <Layout>
    <Seo description="Sonicbox - " lang="es" meta={[]} title="Sonicbox" />
    <Router>
      <PublicRoute path="/" component={Home} />
      <PublicRoute path="/kiosk" component={Kiosk} />
      <PublicRoute path="/kiosk/:code" component={KioskWithCode} />
      <PublicRoute path="/display/:nameseo" component={Display} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/redeem/:code" component={Redeem} />
      <PublicRoute path="/task/:nameseo" component={Task} />
      <PrivateRoute path="/sonicboxes" component={Sonicboxes} />
      <PrivateRoute path="/sonicbox/:nameseo" component={Sonicbox} />
      <PrivateRoute path="/account" component={Account}>
        <PrivateRoute
          path={`/${PROFILE_SECTION_FAVORITES}`}
          component={Favorites}
        />
        <PrivateRoute path={`/${PROFILE_SECTION_ORDERS}`} component={Orders} />
        <PrivateRoute
          path={`/${PROFILE_SECTION_PROFILE}`}
          component={Profile}
        />
      </PrivateRoute>
    </Router>
    <ReactQueryDevtools client={apiQueryClient} initialIsOpen={false} />
  </Layout>
);

export default App;
