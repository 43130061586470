import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";

import { useUser } from "../../../hooks/useApi";

import DevPre from "../../dev/DevPre";
import Loader from "../../basic/Loader";
import FacebookButton from "../../FacebookButton";
import GoogleButton from "../../GoogleButton";

import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "../../../styles/index.scss";
import Button from "../../basic/Button";

export default function Home() {
  const { user, logged, isKiosk, loginRedirect } = useUser();
  const [isLoading] = useState(false); // setIsLoading

  useEffect(() => {
    console.log("useEffect logged", logged);
  }, [isKiosk, logged, loginRedirect]);

  console.log("isLoading", isLoading, "logged", logged);

  return (
    <div className="container index-page">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      <p>
        {logged && user !== null ? user.name + ", bienvenido" : "Bienvenido"} a
        Sonicbox!
      </p>
      <DevPre>
        {typeof logged} - {logged ? "logged" : "not logged"}
      </DevPre>
      <h1>Ponle tu ritmo a la fiesta</h1>
      {isLoading ? (
        <Loader />
      ) : !logged ? (
        <>
          <FacebookButton />
          <GoogleButton />
          <p>
            Debes <Link to="/login">ingresar</Link> para usar Sonicbox
          </p>
        </>
      ) : (
        <Button onClick={() => navigate("/sonicboxes")} className="success">
          Buscá los Sonicboxes más cercanos
        </Button>
      )}
    </div>
  );
}
