import React, { useMemo } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-success.scss";
import Loader from "../basic/Loader";
import cleanSongTitle from "../../utils/cleanSongTitle";
import { useLocal } from "../../hooks/useApi";

const VotedSuccessModal = ({ credits, songname, radioid }) => {
  const { data: localData } = useLocal(radioid);
  const cleanSongname = useMemo(() => cleanSongTitle(songname), [songname]);

  const showCredits = useMemo(() => {
    return localData?.name.indexOf("El Corral") === -1;
  }, [localData]);

  return (
    <Modal className="modal-success voted-success">
      {credits ? (
        <h2>
          <span>¡Genial!</span>
          {showCredits ? (
            <>
              <small>le diste</small>
              <strong>{credits}</strong>
              <small>punto{credits === 1 ? "" : "s"} a</small>
              <span>{cleanSongname}</span>
            </>
          ) : (
            <>
              <small>tu canción</small>
              <strong style={{ fontSize: "3em" }}>ya entró</strong>
              <small>en la lista de reproducción</small>
            </>
          )}
        </h2>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default VotedSuccessModal;
