import React from "react";
import "../../styles/button.scss";

const Button = (props) => {
  /**
   * cleans up custom props that are not suitable for an html <button> attribute
   */
  const filteredKeys = Object.keys(props).filter(
    (key) =>
      key !== "as" &&
      key !== "block" &&
      key !== "className" &&
      key !== "children"
  );

  const buttonProps = filteredKeys.reduce(
    (result, key) => ({ ...result, [key]: props[key] }),
    {}
  );

  return (
    <button
      type="button"
      {...buttonProps}
      className={[
        "button",
        props.as ? `as-${props.as}` : "as-button",
        props.size ? props.size : "normal",
        props.className ? props.className : "",
      ].join(" ")}
      style={{
        width: props.block ? "100%" : "",
        ...props?.style
      }}
    >
      {props.as &&
      (props.as === "link" ||
        props.as === "text" ||
        props.as === "transparent") ? (
        props.children
      ) : (
        <div className="button-content">{props.children}</div>
      )}
    </button>
  );
};

export default Button;
