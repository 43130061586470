import { Fragment } from "react";

export function reactifyBR(string) {
  if (typeof string !== "string") return string;
  return string.split("<br>").map((item, key) => (
    <Fragment key={key}>
      <span>{item}</span>
      <br />
    </Fragment>
  ));
}
