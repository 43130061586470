import React from "react";
import SpinnerSVG from "../../images/spinner.svg";
import "../../styles/loader.scss";

const Loader = ({ style = {} }) => {
  return (
    <img src={SpinnerSVG} alt="Cargando..." className="loader" style={style} />
  );
};

export default Loader;
