import React from "react";
import DigitPad from "../DigitPad";
import Button from "./Button";
import LaggedInput from "./LaggedInput";
import Loader from "./Loader";
import { ReactComponent as BackSpaceSVG } from "../../images/backspace.svg";
import "../../styles/code-widget.scss";

/**
 *
 * @param {object} props
 * @param {string} props.value - the value to be displayed and modified
 * @param {OnChangeCallback} props.onChange - the callback to be executed when the value is modified
 * @param {number} props.codeLength - the number of digigts of the code
 * @param {string} props.errorMessage - error message
 * @returns {JSX.Element}
 */
const CodeWidget = ({
  value,
  onChange,
  codeLength,
  isLoading,
  isError,
  errorMessage,
}) => {
  const handleSetCode = (newCode) => {
    onChange(newCode.substr(-codeLength, codeLength));
  };

  const handleAddDigit = (digit) => {
    if (value.length < codeLength) {
      let newCode = `${value}${digit}`;
      handleSetCode(newCode);
    } else {
      if (isError && value.length === codeLength) {
        onChange(digit.toString());
      }
    }
  };

  return (
    <div className="code-widget">
      <div className="input-wrapper">
        <LaggedInput
          containerClassName="input-well"
          className="code-input"
          value={value}
          onChange={handleSetCode}
        />
        <Button
          onClick={() => onChange("")}
          className="backspace-button"
          disabled={isLoading}
        >
          <BackSpaceSVG />
        </Button>
      </div>
      {isLoading ? <Loader /> : null}
      {errorMessage !== "" ? <p className="error">{errorMessage}</p> : null}
      <DigitPad
        onClick={(digit) => handleAddDigit(digit)}
        disabled={isLoading}
      />
    </div>
  );
};

export default CodeWidget;

/**
 * The callback to be executed when the value changes
 * @callback OnChangeCallback
 * @param {string} value - the modified value
 * @returns {void}
 */
