import React, { useEffect, useState } from "react";
import { useUser } from "../../../hooks/useApi";
import InputText from "../../basic/InputText";
import ProfileHeader from "./components/ProfileHeader";

const Profile = () => {
  const [userState, setUserState] = useState({
    name: "",
    email: "",
    avatar: null,
    country: "",
    city: "",
    phone: "",
  });

  const { userQuery, user } = useUser();

  const { name, country, city, phone } =
    user !== null
      ? user
      : {
          name: "",
          email: "",
          avatar: null,
          country: "",
          city: "",
          phone: "",
        };

  const handleChangeUser = (value, name) => {
    const newState = { ...userState };
    newState[name] = value;
    setUserState(newState);
  };

  useEffect(() => {}, [name, country, city, phone]);

  return (
    <div className="profile">
      <h1>Mis datos</h1>
      <ProfileHeader />
      {userQuery.isLoading ? null : userQuery.isError ? null : (
        <form>
          <InputText
            label="Nombre"
            value={userState.name}
            onChange={handleChangeUser}
            name={"name"}
          />
          <InputText
            label="Pais"
            value={userState.country}
            onChange={handleChangeUser}
            name={"country"}
          />
          <InputText
            label="Ciudad"
            value={userState.city}
            onChange={handleChangeUser}
            name={"city"}
          />
          <InputText
            label="Teléfono"
            value={userState.phone}
            onChange={handleChangeUser}
            name={"phone"}
          />
        </form>
      )}
    </div>
  );
};

export default Profile;
