import React, { useState } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-download-app.scss";
import * as rdd from "react-device-detect";
// import { isAndroid, isIOS, osName } from "react-device-detect";
import AppStoreButton from "../screens/display/components/AppStoreButton";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import { DOWNLOAD_APP_MODAL } from "../../constants";

// if (process.env.NODE_ENV === "development") {
//   rdd.isIOS = true;
//   rdd.osName = "iOS";
// }

const DownloadAppModal = ({ collapsed = true }) => {
  const { closeModal } = useModal(DOWNLOAD_APP_MODAL);
  const [_collapsed, setCollapsed] = useState(collapsed);

  return (
    <Modal
      className={["modal-download-app", _collapsed && "collapsed"].join(" ")}
      onClose={() => setCollapsed(!_collapsed)}
      collapsed={_collapsed}
    >
      <h1 onClick={() => setCollapsed(false)}>Descárgate la App Sonicbox</h1>
      <p>
        Lleva Sonicbox adonde vayas
        <br />y sé siempre el centro de la fiesta
      </p>
      <AppStoreButton platform={rdd.osName} />
      <Button onClick={closeModal} className="cancel-btn">
        Seguir en la web
      </Button>
    </Modal>
  );
};

export default DownloadAppModal;
