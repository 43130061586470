import { useEffect, useRef } from "react";

const usePrevious = (variable) => {
  const ref = useRef(variable);

  useEffect(() => {
    ref.current = variable;
  }, [variable]);

  return ref?.current;
};

export default usePrevious;

export const useValidPrevious = (variable) => {
  const ref = useRef(variable);

  useEffect(() => {
    if (variable !== undefined && variable !== null) {
      ref.current = variable;
    }
  }, [variable]);

  return ref?.current;
};
