import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../basic/Button";
import Modal from "../layout/Modal";
import GreyArrowBackSVG from "../../images/grey-arrow-back.svg";
import { useModal } from "../../hooks/useModal";
import "../../styles/modal-code.scss";
import { useCode, useUser } from "../../hooks/useApi";
import DevPre from "../dev/DevPre";
import CodeWidget from "../basic/CodeWidget";

const CODE_LENGTH = 6;

const CodeModal = () => {
  const { updateUser } = useUser();
  const { closeModal } = useModal();
  const [code, setCode] = useState("");
  const { mutation, sendCode } = useCode(code);
  const sentCode = useRef();

  useEffect(() => {
    console.log("code", code, "current", sentCode.current);
    console.log("code", typeof code, "current", typeof sentCode.current);
    if (code.length === CODE_LENGTH && sentCode.current !== code) {
      sentCode.current = code;
      console.log("sendCode", { ccode: code });
      mutation.reset();
      sendCode(
        {
          ccode: code,
        },
        () => {
          console.log("trigger Callback");
          updateUser();
          closeModal();
        }
      );
    }
  }, [code, sentCode, sendCode, mutation, closeModal, updateUser]);

  const errorMessage = useMemo(() => {
    let message = mutation.isError ? mutation.error.response.data.msg : "";
    switch (message) {
      case "Code does not work":
        return "Código inválido";
      default:
        return message;
    }
  }, [mutation]);

  return (
    <Modal className="modal-code">
      <header>
        <h2>
          Ingresa el código
          <br />
          <small>y canjealo por créditos</small>
        </h2>
      </header>
      {mutation.isError ? (
        <DevPre>{JSON.stringify(mutation.error.response, null, " ")}</DevPre>
      ) : null}
      <CodeWidget
        value={code}
        onChange={setCode}
        codeLength={CODE_LENGTH}
        isLoading={mutation.isLoading}
        isError={mutation.isError}
        errorMessage={errorMessage}
      />

      {/* <div className="input-wrapper">
        <LaggedInput
          containerClassName="input-well"
          className="code-input"
          value={code}
          onChange={handleSetCode}
        />
        <Button
          onClick={() => setCode("")}
          className="backspace-button"
          disabled={mutation.isLoading}
        >
          <img src={BackSpaceSVG} alt="Borrar" />
        </Button>
      </div>
      {mutation.isLoading ? <Loader /> : null}
      {errorMessage !== "" ? <p className="error">{errorMessage}</p> : null}
      <DigitPad
        onClick={(digit) => handleAddDigit(digit)}
        disabled={mutation.isLoading}
      /> */}
      <Button className="bordered" size="big" onClick={closeModal}>
        <img src={GreyArrowBackSVG} alt="flecha hacia atrás" className="icon" />
        <span>Volver</span>
      </Button>
    </Modal>
  );
};

export default CodeModal;
