import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";
import ZeroPadding from "crypto-js/pad-zeropadding";

/**
 * Pads a string with the specified characters at the right
 * @param {string} str
 * @param {number} targetLength
 * @param {string} character
 * @returns {string} the padded string
 */
function padStringRight(str, targetLength, character) {
  if (str.length < targetLength) {
    for (let i = str.length; i < targetLength; i++) {
      str = `${str}${character}`;
    }
  }
  return str;
}

/**
 * Decodes the url cyphered code
 * @param {string} code - URL encode cyphertext
 * @returns {string} plain text
 */
export default function decryptCode(code) {
  console.log("code", code);
  let decryptedUtf8 = "";
  try {
    const base64notPadded = code.replaceAll("-", "+").replaceAll("_", "/");
    console.log("base64notPadded", base64notPadded);
    const base64 = padStringRight(
      base64notPadded,
      Math.ceil(base64notPadded.length / 4) * 4,
      "="
    );
    const encrypted = base64;
    console.log("encrypted", encrypted);
    const key = Utf8.parse("BMTrGHbQurUYGICmcOODpu0OOqqbQpBO");
    const iv = Base64.parse("u18yt7OjMgm5bZGApYAz+w==");
    console.log("iv", iv);
    const decrypted = AES.decrypt(encrypted, key, {
      iv: iv,
      padding: ZeroPadding,
    });
    console.log("decrypted", decrypted);
    decryptedUtf8 = decrypted.toString(Utf8);
  } catch (e) {
    console.warn(e);
  }
  return decryptedUtf8;
}
