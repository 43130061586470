export default function cleanSongTitle(title, artist = "") {
  if (typeof title === "string" && title !== "") {
    let title_2 = title;
    if (artist !== "") {
      try {
        const artistRegEx = new RegExp(artist, "g");
        title_2 = title.replace(artistRegEx, "");
      } catch (e) {
        console.warn(e);
      }
    }
    const splitted = title_2.split(" - ");
    if (splitted.length > 1) {
      removeRepeated(splitted);
      removeEmpty(splitted);
    }
    const rejoin = splitted.join(" - ");

    const matchDJcode = rejoin.match(/(.*)(\s-\s\d+[A-Z]\s-\s\d+)/);
    if (matchDJcode !== null && matchDJcode.length > 1) {
      return matchDJcode[1];
    }
    return rejoin;
  }
  return title;
}

/**
 * Mutates an array removing repeated elements
 * @param {[string|boolean|number]} array an array of comparable elements
 * @return {void}
 */
function removeRepeated(array) {
  for (let indexA = array.length - 1; indexA >= 0; indexA--) {
    const elementA = array[indexA];
    for (let indexB = indexA - 1; indexB >= 0; indexB--) {
      const elementB = array[indexB];
      if (elementA === elementB) {
        array.splice(indexA, 1);
      }
    }
  }
}

/**
 * Mutates an array removing empty strings
 * @param {[string|boolean|number]} array an array of comparable elements
 * @return {void}
 */
function removeEmpty(array) {
  for (let indexA = array.length - 1; indexA >= 0; indexA--) {
    const elementA = array[indexA].trim();
    if (elementA === "") {
      array.splice(indexA, 1);
    }
  }
}
