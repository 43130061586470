import React, { useMemo } from "react";
import Button from "./basic/Button";
import { ReactComponent as HeartFillSVG } from "../inline-svg/heart-fill.svg";
import { ReactComponent as HeartSVG } from "../inline-svg/heart.svg";
import { useFavorites, useUser } from "../hooks/useApi";
import Loader from "./basic/Loader";
import "../styles/favorite-button.scss";

const FavoriteButton = ({ songid }) => {
  const { isKiosk } = useUser();

  const {
    data: favorites,
    isLoading,
    addFavorite,
    removeFavorite,
    isMutating,
  } = useFavorites();

  const cfid = useMemo(() => {
    if (favorites) {
      const found = favorites.find((favorite) => favorite.songid === songid);
      if (found !== undefined) {
        return found.cfid;
      }
    }
    return undefined;
  }, [songid, favorites]);

  const handleToggleFavorite = () => {
    if (cfid !== undefined) {
      removeFavorite(songid);
    } else {
      addFavorite(songid);
    }
  };
  if (isKiosk) {
    return null;
  }
  return (
    <Button className="heart" onClick={handleToggleFavorite} as="transparent">
      {isLoading || isMutating ? (
        <Loader />
      ) : cfid ? (
        <HeartFillSVG />
      ) : (
        <HeartSVG />
      )}
    </Button>
  );
};

export default FavoriteButton;
