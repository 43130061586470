import React from "react";
import Button from "./basic/Button";
import "../styles/points-input-widget.scss";

const PointsInputWidget = ({ value, onChange, max = 99999 }) => {
  const handleSetCredits = (newValue) => {
    onChange(parseInt(Math.max(1, Math.min(max, newValue))));
  };

  return (
    <div className="points-input-widget">
      <Button onClick={() => handleSetCredits(value - 1)} className="btn-minus">
        -
      </Button>
      <div className="input-well">
        <input
          type="number"
          min="1"
          max={max}
          step="1"
          value={value === null ? undefined : value}
          onChange={(e) => handleSetCredits(e.target.value)}
          className={`digits-${Math.ceil(Math.log10(value + 1))}`}
        />
      </div>
      <Button onClick={() => handleSetCredits(value + 1)} className="btn-plus">
        +
      </Button>
    </div>
  );
};

export default PointsInputWidget;
