export default function ProgressBar({ progress, color }) {
  return (
    <div
      className="progress-bar"
      style={{
        height: 30,
        backgroundColor: "#0008",
        overflow: "hidden",
      }}
    >
      <div
        className="progress-bar__progress"
        style={{
          height: "100%",
          width: `${progress}%`,
          backgroundColor: color,
        }}
      />
    </div>
  );
}
