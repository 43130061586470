import React from "react";
import { useLocal, useUser } from "../../hooks/useApi";

const LocalHeader = () => {
  const { isKiosk, user } = useUser();
  const { data: localData, isLoading, isError } = useLocal();

  if (isLoading || isError || localData === undefined) return null;

  const { name, radioimage } = localData;

  return (
    <div className="local-header">
      <img
        src={isKiosk ? user.avatar : radioimage}
        alt={`logo ${name}`}
        className="local-logo"
      />
      <h3>{name}</h3>
    </div>
  );
};

export default LocalHeader;
