import React, { useState, useEffect } from "react";
import { useCards } from "../hooks/useApi";
import Button from "./basic/Button";
import InputText from "./basic/InputText";
import Loader from "./basic/Loader";
import "../styles/forms.scss";
import DevPre from "./dev/DevPre";

const CreditCardForm = ({ onSuccess, onCancel }) => {
  const { addCardMutation, addCard } = useCards();
  const { isLoading, isError, error, isSuccess } = addCardMutation;
  const [name, setName] = useState("");
  const [cardNum, setCardNum] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [stripeToken] = useState("a");
  const [cvc, setCvc] = useState("");

  const handleOnSubmit = () => {
    addCard({
      name: name,
      card_num: cardNum,
      exp_month: expMonth,
      exp_year: `20${expYear}`,
      stripeToken: stripeToken,
      cvc: cvc,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [onSuccess, isSuccess]);

  useEffect(() => {
    setExpYear(expYear.substr(-2, 2));
  }, [expYear]);

  const errorMessage = isError ? error.response.data.msg : null;

  return (
    <>
      <DevPre>{isError ? JSON.stringify(error, null, " ") : ""}</DevPre>
      {errorMessage ? <p className="error">{errorMessage}</p> : null}
      <form onSubmit={handleOnSubmit}>
        <InputText
          label="Nombre"
          value={name}
          onChange={(value) => setName(value)}
          placeholder="Nombre"
        />
        <InputText
          label="Número de tarjeta"
          value={cardNum}
          onChange={(value) => setCardNum(value)}
          placeholder="Número de tarjeta"
        />
        <div className="form-group">
          <InputText
            label="Venc. mes"
            value={expMonth}
            onChange={(value) => setExpMonth(value)}
            type="number"
            min={1}
            max={12}
            step={1}
            placeholder="12"
          />
          <InputText
            label="año"
            value={expYear}
            onChange={(value) => setExpYear(value)}
            type="number"
            min={new Date().getFullYear() - 2000}
            max={50}
            step={1}
            placeholder="23"
          />
          <InputText
            label="CVC"
            value={cvc}
            onChange={(value) => setCvc(value)}
            placeholder="CVC"
          />
        </div>
        <Button
          className="bordered success"
          size="big"
          onClick={handleOnSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : `Agregar tarjeta`}
        </Button>
      </form>
    </>
  );
};

export default CreditCardForm;
