import React, { useState, useEffect, useMemo } from "react";
import { useLocation, Link, globalHistory } from "@reach/router";
import * as rdd from "react-device-detect";

import { useUser, useLocal } from "../../hooks/useApi";
import "../../styles/navbar.scss";
import LocalHeader from "./LocalHeader";
import MenuToggleSVG from "../../inline-svg/menu-toggle.svg";
import { useModal } from "../../hooks/useModal";
import {
  ADD_CREDITS_MODAL,
  BUY_MODAL,
  CODE_MODAL,
  PROFILE_SECTION_FAVORITES,
  PROFILE_SECTION_ORDERS,
  PROFILE_SECTION_PROFILE,
} from "../../constants";
import DevPre from "../dev/DevPre";
import ProfileHeader from "../screens/account/components/ProfileHeader";
import { ReactComponent as DigitPadSVG } from "../../inline-svg/digitpad.svg";
import { ReactComponent as CreditCardSVG } from "../../inline-svg/creditcard.svg";
import { ReactComponent as SonicboxSVG } from "../../inline-svg/sonicbox.svg";
import { ReactComponent as OrdersSVG } from "../../inline-svg/orders.svg";
import { ReactComponent as SongsSVG } from "../../inline-svg/songs.svg";
import { ReactComponent as ProfileSVG } from "../../inline-svg/profile.svg";
import Button from "../basic/Button";
import { useValidPrevious } from "../../hooks/usePrevious";
import PointsInputWidget from "../PointsInputWidget";
import { isMobile } from "react-device-detect";
import AppStoreButton from "../screens/display/components/AppStoreButton";

export default function NavBar({ siteTitle }) {
  const location = useLocation();

  const [menuExpanded, setMenuExpanded] = useState(false);
  const { logged, user, isKiosk, loginRedirect } = useUser();
  const { data: local, radioid } = useLocal();
  const { openModal: openAddCreditsModal } = useModal(ADD_CREDITS_MODAL);
  const { openModal: openCodeModal } = useModal(CODE_MODAL);
  const { openModal: openBuyModal } = useModal(BUY_MODAL);

  const lastRadioid = useValidPrevious(radioid);
  const { data: lastLocal } = useLocal(lastRadioid);

  const [zoom, setZoom] = useState(null);

  useEffect(() => {
    if (zoom === null) {
      const storedZoom = window.localStorage.getItem("zoom");
      if (storedZoom) {
        const newZoom = Math.max(1, parseInt(storedZoom));
        setZoom(newZoom);
      } else {
        setZoom(16);
      }
    }
    window.localStorage.setItem("zoom", zoom);
    document.body.style.fontSize = zoom + "px";
    console.log("new zoom", typeof zoom, zoom);
  }, [zoom]);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setMenuExpanded(false);
    });
  }, [setMenuExpanded]);

  const handleOpenCodeModal = () => {
    setMenuExpanded(false);
    openCodeModal();
  };

  const handleOpenBuyModal = () => {
    setMenuExpanded(false);
    openBuyModal();
  };

  /**
   * adds a global style preventing the double scrolling when the menu scrolls
   */
  const bodyCSS = `body {overflow: hidden}`;

  const isDisplay = location.pathname.indexOf("/display") === 0;

  const showPoints = useMemo(
    () => local?.name.indexOf("El Corral") !== 0,
    [local]
  );

  return (
    <>
      <div
        className={[
          "navbar-container",
          "dark",
          isDisplay ? "display" : "",
        ].join(" ")}
      >
        <div className="container">
          <DevPre>{loginRedirect}</DevPre>
          <div
            className={["navbar", menuExpanded ? "expanded" : "collapsed"].join(
              " "
            )}
          >
            <DevPre>
              {typeof logged} {logged ? "logged" : "not logged"}
              <hr />
              {typeof user} {JSON.stringify(user, null, " ")}
              <hr />
              {isKiosk ? "is kiosk" : "is user"}
            </DevPre>

            <button
              type="button"
              className="toggle-menu-btn"
              onClick={() => setMenuExpanded(!menuExpanded)}
            >
              <img src={MenuToggleSVG} alt="Menu" />
              {/* <MenuToggleSVG /> */}
            </button>
            <LocalHeader radioid={radioid} />

            {showPoints && !isDisplay && logged && user !== null ? (
              <>
                <div
                  className="credits-container"
                  onClick={openAddCreditsModal}
                >
                  <span className="credits">{user.points}</span>
                  <span className="label">créditos</span>
                </div>
                {local && !isNaN(local.fcreditsuser) ? (
                  <div
                    className="credits-container fcredits-container"
                    onClick={openAddCreditsModal}
                  >
                    <span className="credits">{local.fcreditsuser}</span>
                    <span className="label">gratis</span>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="nav-menu-wrapper">
        <div
          className={[
            "nav-menu-container",
            menuExpanded ? "expanded" : "collapsed",
          ].join(" ")}
        >
          <header>{logged ? <ProfileHeader /> : null}</header>
          <nav>
            {logged ? (
              <>
                {!isKiosk ? (
                  <Link to="/sonicboxes">
                    <SonicboxSVG className="icon" />
                    <span>Sonicboxes</span>
                  </Link>
                ) : null}
                {!isKiosk &&
                radioid === null &&
                lastLocal !== null &&
                lastLocal !== undefined ? (
                  <Link
                    to={`/sonicbox/${lastLocal.nameseo}`}
                    className="local-link"
                  >
                    <img
                      src={lastLocal.radioimage}
                      alt={lastLocal.name}
                      className="radio-image"
                    />
                    <span>{`Volver a ${lastLocal.name}`}</span>
                  </Link>
                ) : null}
                {!isDisplay && !isKiosk ? (
                  <Button as="link" onClick={handleOpenCodeModal}>
                    <DigitPadSVG className="icon" />
                    {/* <img
                    src={DigitPadSVG}
                    alt="code pade icon"
                    className="icon"
                  /> */}
                    <span>Ingresar código</span>
                  </Button>
                ) : null}
                {!isKiosk ? (
                  <Button as="link" onClick={handleOpenBuyModal}>
                    <CreditCardSVG className="icon" />
                    {/* <img
                    src={CreditCardSVG}
                    alt="code pade icon"
                    className="icon"
                  /> */}
                    <span>Comprar créditos</span>
                  </Button>
                ) : null}
                {!isKiosk ? (
                  <>
                    <Link to={`/account/${PROFILE_SECTION_ORDERS}`}>
                      <OrdersSVG className="icon" />
                      <span>Mis compras</span>
                    </Link>
                    <Link to={`/account/${PROFILE_SECTION_FAVORITES}`}>
                      <SongsSVG className="icon" />
                      <span>Mis canciones</span>
                    </Link>
                    <Link to={`/account/${PROFILE_SECTION_PROFILE}`}>
                      <ProfileSVG className="icon" />
                      <span>Mis datos</span>
                    </Link>
                  </>
                ) : null}
              </>
            ) : (
              <Link to="/">Ingresar</Link>
            )}
            {isMobile ? (
              <div
                style={{ filter: "invert(1) hue-rotate(180deg) saturate(3)" }}
              >
                <AppStoreButton platform={rdd.osName} />
              </div>
            ) : (
              <div className="input-container">
                <label>Zoom</label>
                <Button
                  onClick={() => setZoom(16)}
                  disabled={zoom === 16}
                  className="reset-zoom-btn"
                >
                  Restablecer zoom
                </Button>
                <PointsInputWidget
                  value={isNaN(zoom) ? "" : zoom}
                  onChange={(value) => setZoom(parseInt(value))}
                />
              </div>
            )}
          </nav>
        </div>
        <div
          className={[
            "menu-backdrop",
            menuExpanded ? "expanded" : "collapsed",
          ].join(" ")}
          onClick={() => setMenuExpanded(false)}
        />
      </div>
      {menuExpanded ? <style>{bodyCSS}</style> : null}
    </>
  );
}
