import { useCallback, useMemo, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Button from "../../../basic/Button";
import "../styles/task-memory.scss";

export default function Memory({
  taskData,
  values,
  setValues,
  setIsValid,
  onSuccess,
}) {
  const isFlipping = useRef(false);
  const containerRef = useRef();
  const [cards, setCards] = useState([]);
  const [ready, setReady] = useState(false);
  const [tries, setTries] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    setContainerWidth(containerWidth);
  }, []);

  useEffect(() => {
    if (!ready) {
      const newCards = [
        ...taskData.cards.map((card) => {
          return {
            image: card,
            flipped: true,
            matched: false,
          };
        }),
        ...taskData.cards.map((card) => {
          return {
            image: card,
            flipped: true,
            matched: false,
          };
        }),
      ];
      // scramble cards
      for (let i = newCards.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newCards[i], newCards[j]] = [newCards[j], newCards[i]];
      }
      setCards(newCards);
      // onReady([...newCards]);

      console.log("onReady", newCards);
      setTimeout(() => {
        if (!ready) {
          const flippedCards = newCards.map((card) => ({
            ...card,
            flipped: false,
          }));
          console.log("onReady timeout", flippedCards);
          setCards(flippedCards);
        }
      }, 2000);
    }
  }, [taskData, ready]);

  const handleCardClick = useCallback(
    (index) => {
      console.log("handle card click");
      if (!ready) {
        setReady(true);
      } else if (!isFlipping.current) {
        const newCards = [...cards];
        const currentCard = newCards[index];
        if (!currentCard.flipped && !currentCard.matched) {
          console.log(JSON.stringify(newCards, null, 2));
          currentCard.flipped = true;
          const flippedCards = newCards.filter((card) => card.flipped);
          console.log("flippedCards", flippedCards);
          if (flippedCards.length > 1) {
            isFlipping.current = true;
            if (flippedCards.length === 2) {
              setTries(tries + 1);
              if (tries === 0) {
                setStartTime(new Date().getTime());
              }
              if (flippedCards[0].image === flippedCards[1].image) {
                flippedCards[0].matched = true;
                flippedCards[1].matched = true;
              }
            }
            setTimeout(() => {
              setCards(
                [...newCards].map((card) => ({ ...card, flipped: false }))
              );
              isFlipping.current = false;
            }, 1000);
          }
          setCards(newCards);
        }
      }
    },
    [cards, ready, tries, isFlipping]
  );

  const isSolved = useMemo(() => {
    const countMatch = cards.filter((card) => card.matched).length;
    return countMatch === cards.length && countMatch > 0;
  }, [cards]);

  const [cardWidth, cardHeight, adjustedContainerWidth, countX] =
    useMemo(() => {
      if (containerRef.current) {
        const count = cards.length;
        const size = Math.sqrt(count);
        let countX = Math.floor(size);
        if (window.innerHeight < window.innerWidth) {
          countX = Math.ceil(size);
        }
        const width = (containerWidth - 8) / countX;
        const height = (width * taskData.height) / taskData.width;
        const containerHeight = Math.ceil(count / countX) * height + 8;
        console.log(containerHeight, containerWidth);
        if (window.innerHeight - 100 < containerHeight) {
          const shrinkRatio = (window.innerHeight - 100) / containerHeight;
          const adjustedContainerWidth = containerWidth * shrinkRatio;
          return [
            width * shrinkRatio,
            height * shrinkRatio,
            adjustedContainerWidth,
            countX,
          ];
        }
        return [width, height, containerWidth, countX];
      }
      return [40, 40, 4];
    }, [taskData, cards, containerWidth]);

  useEffect(() => {
    if (isSolved && !values?.time) {
      const endTime = new Date().getTime();
      const time = Math.round((endTime - startTime) / 1000);
      const newValues = { ...values, time, tries };
      console.log(endTime, startTime);
      console.log("solved", newValues);
      setValues(newValues);
      setIsValid(true);
    }
  }, [isSolved, startTime, tries, values, setValues, setIsValid]);

  return (
    <div className="memory">
      <h2>
        {`Memoria`}{" "}
        <small style={{ fontWeight: "300", fontSize: "0.75rem" }}>
          {tries}
        </small>
      </h2>
      <div
        className="memory-content"
        ref={containerRef}
        style={{ width: cardWidth * countX, margin: "0 auto" }}
      >
        {cards.map((card, index) => (
          <div
            key={index.toString()}
            className={[
              "memory-card-container",
              ready ? "ready" : "",
              card.flipped ? "flipped" : "",
              card.matched ? "matched" : "",
            ].join(" ")}
            onClick={() => handleCardClick(index)}
            style={{
              width: cardWidth,
              height: cardHeight,
            }}
          >
            <div
              className="memory-card-front"
              style={{ backgroundImage: `url(${card.image}` }}
            />
            <div
              className="memory-card-back"
              style={{ backgroundImage: `url(${taskData.back}` }}
            />
          </div>
        ))}
      </div>
      {isSolved && (
        <div className="memory-solved">
          <h2>¡Felicitaciones!</h2>
          <p>{`Has completado la tarea en ${tries} movimiento${
            tries !== 1 && "s"
          } y ${values?.time} segundos`}</p>
          <Button onClick={() => setReady(false)} className={"success"}>
            Reiniciar
          </Button>
          <Button onClick={() => onSuccess()} className={"success"}>
            Continuar
          </Button>
        </div>
      )}
    </div>
  );
}
