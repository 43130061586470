import React from "react";
import Button from "./basic/Button";
import "../styles/digit-pad.scss";

const DIGITS_PAD = [[7, 8, 9], [4, 5, 6], [1, 2, 3], [0]];

/**
 * A component rendering a 10 digits keyboard to input single digits
 * @param {object} props
 * @param {OnClickCallback} props.onClick
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
const DigitPad = ({ onClick, disabled }) => {
  return (
    <div className="digit-pad">
      {DIGITS_PAD.map((row, index) => (
        <div className="digit-row" key={index.toString()}>
          {row.map((digit) => (
            <Button
              key={digit.toString()}
              onClick={() => onClick(digit)}
              className="bordered digit-button"
              disabled={disabled}
            >
              {digit}
            </Button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DigitPad;

/**
 * @callback OnClickCallback
 * @param {number} digit - the clicked digit
 * @returns {void}
 */
