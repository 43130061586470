import React from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-vote.scss";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import { BUY_MODAL, CODE_MODAL, VOTE_MODAL } from "../../constants";
import Locales from "../screens/sonicboxes/components/Locales";
import { useUser } from "../../hooks/useApi";
import { navigate } from "@reach/router";

const AddSongToRadioModal = ({ songid, songname, artist, cover, scid }) => {
  const { stackModal: openCodesModal } = useModal(CODE_MODAL);
  const { stackModal: openBuyModal } = useModal(BUY_MODAL);
  const { stackModal: openVoteModal } = useModal(VOTE_MODAL);
  const { user } = useUser();

  return (
    <Modal className="modal-add-song-to-radio modal-vote">
      <p className="credits-message">
        <strong>{`${user.points === 0 ? "No tienes" : user.points} credito${
          user.points === 1 ? "" : "s"
        }`}</strong>{" "}
        {`disponible${user.points === 1 ? "" : "s"}`}
      </p>

      <h2>¿Elige en qué radio estás?</h2>

      <Locales
        onEnter={(nameseo, radioid) => {
          navigate(`/sonicbox/${nameseo}`);
          openVoteModal({
            songid: songid,
            songname: songname,
            artist: artist,
            cover: cover,
            radioid: radioid,
          });
        }}
        buttonLabel="Votar aquí"
      />

      <Button size="big" className="bordered brand" onClick={openBuyModal}>
        Comprar créditos
      </Button>

      <Button size="big" className="bordered brand" onClick={openCodesModal}>
        Ingresar código
      </Button>
    </Modal>
  );
};

export default AddSongToRadioModal;
