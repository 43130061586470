import React, { forwardRef, useEffect, useState } from "react";
import Loader from "./Loader";

const LoadableImage = ({ src, alt, className = "", style = {} }, ref) => {
  const [loaded, setLoaded] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setHide(true);
      }, 5000);
    }
  }, [src, loaded]);

  if (hide && !loaded) return null;

  return (
    <>
      <img
        ref={ref}
        src={src}
        alt={alt}
        style={{ ...style, opacity: loaded ? 1 : 0 }}
        className={className}
        onLoad={() => setLoaded(true)}
      />
      {loaded ? null : <Loader style={{ position: "absolute" }} />}
    </>
  );
};

export default forwardRef(LoadableImage);
