import { useEffect, useState } from "react";

const useGeoLocation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      console.log("Geolocation Available");
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setIsLoading(false);
          setError(null);
        },
        (geoPositionError) => {
          alert(
            "No hemos podido obtener tu ubicación, por favor habilita la geolocalización, para encontrar las Sonicboxes cercanas"
          );
          setIsLoading(false);
          setError(geoPositionError.message);
          console.warn("geoPositionError", geoPositionError.message);
        }
      );
    } else {
      console.log("Geolocation Not Available");
    }
  }, []);

  return {
    isLoading: isLoading,
    error: error,
    lat: lat,
    lng: lng,
  };
};

export default useGeoLocation;
