import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import useInterval from "../../../../hooks/useInterval";
import Button from "../../../basic/Button";

const CELL_SIZE = 60;
const GRID_WIDTH = 5;
const GRID_HEIGHT = 10;
const INTERVAL = 25;
const SLOW_SPEED = -0.05;
const FAST_SPEED = -0.5;
const POSSIBLE_VALUES = [2, 4, 8, 16, 32, 64, 128];

export default function Merge({
  taskData,
  values,
  setValues,
  setIsValid,
  onSuccess,
}) {
  const indexCounter = useRef(0);
  const [ready, setReady] = useState(false);
  const [points, setPoints] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const createPiece = () => ({
    x: Math.floor(Math.random() * GRID_WIDTH),
    y: GRID_HEIGHT,
    value: POSSIBLE_VALUES[Math.floor(Math.random() * POSSIBLE_VALUES.length)],
    index: indexCounter.current++,
    speed: SLOW_SPEED,
  });

  const [pieces, setPieces] = useState(new Array(GRID_WIDTH).fill([]));
  const [dyingPieces, setDyingPieces] = useState([]);
  const [fallingPiece, setFallingPiece] = useState(null);
  const time = useRef(0);
  const [timer, setTimer] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const ticker = useCallback(() => {
    if (!isFinished && ready) {
      if (startTime === null) {
        setStartTime(new Date().getTime());
      }
      // console.log("ticker");
      time.current = time.current + INTERVAL;
      setTimer(Math.round(time.current / 1000));

      let newPieces = [...pieces];
      const countAllPieces = pieces.reduce(
        (count, column) => count + column.length,
        0
      );
      let newDyingPieces = [...dyingPieces];
      let newPoints = points;

      if (countAllPieces === 0 && fallingPiece === null) {
        const newPiece = createPiece();
        setFallingPiece(newPiece);
      } else {
        const newY = fallingPiece.y + fallingPiece.speed;
        let canFall = newY > newPieces[fallingPiece.x].length;
        // console.log(
        //   fallingPiece.y,
        //   newY,
        //   newPieces[fallingPiece.x].length,
        //   canFall
        // );
        if (canFall) {
          fallingPiece.y = newY;
        } else {
          console.log("pieces", newPieces);
          fallingPiece.y = newPieces[fallingPiece.x].length;

          // attach to ground or piece below
          const colMaxHeight = newPieces[fallingPiece.x].length;
          fallingPiece.y = colMaxHeight;
          newPieces[fallingPiece.x] = [
            ...newPieces[fallingPiece.x],
            { ...fallingPiece },
          ];
          console.log("attached to ground", fallingPiece, newPieces);

          // check if adjacent tiles can be merged
          let someMerged = true;
          while (someMerged) {
            const mergedPieces = [];
            someMerged = false;
            // eslint-disable-next-line no-loop-func
            newPieces.forEach((column, x) => {
              if (someMerged) return;
              column.forEach((pieceA, y) => {
                if (x > 0 && newPieces[x - 1].length > y) {
                  const pieceB = newPieces[x - 1][y];
                  const [mergedPiece, leftPiece] = checkTouchedPieces(
                    pieceA,
                    pieceB
                  );
                  if (mergedPiece !== null && leftPiece !== null) {
                    console.log("merged", mergedPiece, leftPiece);
                    mergedPieces.push(mergedPiece);
                    leftPiece.value += mergedPiece.value;
                    newPoints += mergedPiece.value;
                    leftPiece.changed = 5000;
                    someMerged = true;
                    return;
                  }
                }
                if (x < GRID_WIDTH - 1 && newPieces[x + 1].length > y) {
                  const pieceB = newPieces[x + 1][y];
                  const [mergedPiece, leftPiece] = checkTouchedPieces(
                    pieceA,
                    pieceB
                  );
                  if (mergedPiece !== null && leftPiece !== null) {
                    console.log("merged", mergedPiece, leftPiece);
                    mergedPieces.push(mergedPiece);
                    leftPiece.value += mergedPiece.value;
                    newPoints += mergedPiece.value;
                    leftPiece.changed = 5000;
                    someMerged = true;
                    return;
                  }
                }
                if (newPieces[x].length > 0 && y > 0) {
                  console.log("pieceA", pieceA, "pieceB", x, y);
                  const pieceB = newPieces[x][y - 1];
                  const [mergedPiece, leftPiece] = checkTouchedPieces(
                    pieceA,
                    pieceB
                  );
                  if (mergedPiece !== null && leftPiece !== null) {
                    console.log("merged", mergedPiece, leftPiece);
                    mergedPieces.push(mergedPiece);
                    leftPiece.value += mergedPiece.value;
                    newPoints += mergedPiece.value;
                    leftPiece.changed = 5000;
                    someMerged = true;
                    return;
                  }
                }
                if (newPieces[x].length > y + 1) {
                  const pieceB = newPieces[x][y + 1];
                  const [mergedPiece, leftPiece] = checkTouchedPieces(
                    pieceA,
                    pieceB
                  );
                  if (mergedPiece !== null && leftPiece !== null) {
                    console.log("merged", mergedPiece, leftPiece);
                    mergedPieces.push(mergedPiece);
                    leftPiece.value += mergedPiece.value;
                    newPoints += mergedPiece.value;
                    leftPiece.changed = 5000;
                    someMerged = true;
                    return;
                  }
                }
              });
            });
            console.log("mergedPieces", mergedPieces.length);
            // move pieces over left and right merged pieces down
            // eslint-disable-next-line no-loop-func
            mergedPieces.forEach((piece) => {
              piece.dieIn = 200;
              if (piece.x !== fallingPiece.x) {
                newPieces[piece.x].forEach((p) => {
                  if (p.y > piece.y) {
                    p.y--;
                  }
                });
              }
            });

            newDyingPieces = [...newDyingPieces, ...mergedPieces];

            // remove merged piece
            console.log("column", newPieces);
            newPieces = newPieces.map((column) => {
              const filteredColumn = column.filter(
                (p) =>
                  typeof mergedPieces.find(
                    (merged) => merged.index === p.index
                  ) === "undefined"
              );
              console.log(
                "filtered column",
                typeof filteredColumn,
                filteredColumn.length
              );
              return filteredColumn;
            });
          }

          // create a new piece
          const createdPiece = createPiece();
          setFallingPiece(createdPiece);
        }
      }
      setPoints(newPoints);
      setDyingPieces(
        newDyingPieces
          .map((piece) => ({ ...piece, dieIn: piece.dieIn - INTERVAL }))
          .filter((piece) => piece.dieIn > 0)
      );
      setPieces(newPieces);
      const higherColumn = newPieces.reduce(
        (val, column) => Math.max(val, ...column.map((piece) => piece.y)),
        0
      );
      // console.log("higherColumn", higherColumn);
      if (higherColumn >= GRID_HEIGHT - 2) {
        setIsFinished(true);
        const endTime = new Date().getTime();
        const time = Math.round((endTime - startTime) / 1000);
        const newValues = { ...values, time, points };
        setValues(newValues);
        setIsValid(true);
      }
    }
  }, [
    isFinished,
    ready,
    startTime,
    pieces,
    dyingPieces,
    points,
    fallingPiece,
    values,
    setValues,
    setIsValid,
  ]);

  useInterval(ticker, INTERVAL, [
    isFinished,
    ready,
    startTime,
    pieces,
    dyingPieces,
    points,
    fallingPiece,
    values,
    setValues,
    setIsValid,
  ]);

  const handleRestart = () => {
    indexCounter.current = 0;
    setReady(false);
    setPoints(0);
    setStartTime(null);
    setPieces(new Array(GRID_WIDTH).fill([]));
    setDyingPieces([]);
    setFallingPiece(null);
    time.current = 0;
    setTimer(0);
    setIsFinished(false);
  };

  const handleClick = useCallback(
    (e) => {
      if (fallingPiece) {
        const container = e.currentTarget;
        const mousePositionX = e.pageX - container.offsetLeft;
        const mouseColumn = Math.min(
          GRID_WIDTH,
          Math.max(0, Math.floor(mousePositionX / CELL_SIZE))
        );
        fallingPiece.x = mouseColumn;
        fallingPiece.speed = FAST_SPEED;
        setFallingPiece({ ...fallingPiece });
      }
    },
    [fallingPiece]
  );

  return (
    <div
      style={{
        ...(!isFinished &&
          ready && {
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            backgroundColor: "#333",
            zIndex: 1000,
          }),
      }}
    >
      <div
        onClick={handleClick}
        style={{
          margin: "0 auto",
          width: CELL_SIZE * GRID_WIDTH,
          height: CELL_SIZE * GRID_HEIGHT,
          backgroundColor: "#000",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: CELL_SIZE * 1.5,
            position: "absolute",
            top: 0,
            left: 0,
            borderBottomWidth: 1,
            borderColor: "#fff",
            borderBottomStyle: "dashed",
            padding: 10,
            fontSize: "1.5rem",
            fontWeight: "500",
            textAlign: "right",
          }}
        >
          {points}
        </div>
        {false && (
          <pre
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              color: "#0f0",
              margin: 0,
              textAlign: "left",
            }}
          >
            {timer} -{" "}
            {pieces.reduce((count, column) => count + column.length, 0)} -{" "}
            {fallingPiece?.index} -{" "}
            {JSON.stringify(pieces.map((column) => column.length))}
            <hr />
            y: {fallingPiece && (fallingPiece.y - 1) * CELL_SIZE}
            {JSON.stringify(fallingPiece, null, " ")}
            <br />
            {JSON.stringify(dyingPieces, null, " ")}
          </pre>
        )}
        {fallingPiece && <Piece {...fallingPiece} falling={true} />}
        {pieces.map((columns, colindex) =>
          columns.map((piece, index) => (
            <Piece key={`${piece.index}`} {...piece} />
          ))
        )}
        {dyingPieces.map((piece, index) => (
          <Piece key={`${piece.index}`} {...piece} />
        ))}
      </div>
      {!ready && (
        <div className="memory-solved">
          <h2>¿Listo para comenzar?</h2>
          <p>Une las piezas con el mismo valor</p>
          <Button onClick={() => setReady(true)} className={"success"}>
            Comenzar
          </Button>
        </div>
      )}
      {isFinished && (
        <div className="memory-solved">
          <h2>¡Felicitaciones!</h2>
          <p>{`Has completado la tarea con ${points} punto${
            points !== 1 && "s"
          }  en ${values?.time} segundos`}</p>
          <Button onClick={handleRestart} className={"success"}>
            Reiniciar
          </Button>
          <Button onClick={() => onSuccess()} className={"success"}>
            Continuar
          </Button>
        </div>
      )}
    </div>
  );
}

const PALETTE = {
  2: "#5c1fc5",
  4: "#e92f8f",
  8: "#08f422",
  16: "#eb31d9",
  32: "#e54b18",
  64: "#c722dc",
  128: "#55cd75",
  256: "#c3d3b8",
  512: "#a969f9",
  1024: "#688d44",
};

const Piece = ({ x, y, value, changed, falling = false, dieIn = false }) => {
  const size = useMemo(() => Math.log(3 + 20 / value), [value]);

  return (
    <div
      style={{
        position: "absolute",
        top: (GRID_HEIGHT - y - 1) * CELL_SIZE,
        left: x * CELL_SIZE,
        width: CELL_SIZE,
        height: CELL_SIZE,
        backgroundColor: PALETTE[value] || "#fff",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: dieIn > 0 ? 0.25 : 1,
        transform: dieIn > 0 ? `scale(${dieIn / 200})` : "none",
        transition: `all ${falling ? INTERVAL : 300}ms linear`,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#222",
        borderStyle: "solid",
      }}
    >
      <div
        style={{
          fontSize: `${size}rem`,
          fontWeight: "700",
        }}
      >
        {value}
      </div>
    </div>
  );
};

function checkTouchedPieces(pieceA, pieceB) {
  if (
    pieceA.value === pieceB.value &&
    ((pieceA.x === pieceB.x && pieceB.y === pieceA.y + 1) ||
      (pieceB.x === pieceA.x && pieceA.y === pieceB.y + 1) ||
      (pieceA.x === pieceB.x + 1 && pieceA.y === pieceB.y) ||
      (pieceA.x === pieceB.x - 1 && pieceA.y === pieceB.y))
  ) {
    if (pieceA.y > pieceB.y || pieceA.index > pieceB.index) {
      return [pieceA, pieceB];
    } else {
      return [pieceB, pieceA];
    }
  }
  return [null, null];
}
