import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FACEBOOK_APP_ID } from "../constants";
import { useUser } from "../hooks/useApi";
import Button from "./basic/Button";
import Loader from "./basic/Loader";

export default function FacebookButton() {
  const { login, mutation } = useUser();

  const [errorMessage, setErrorMessage] = useState("");

  const responseFacebook = (response) => {
    console.log("Facebook login response", response);

    if (response && response.hasOwnProperty("accessToken")) {
      if (response.email !== undefined) {
        const dataToSend = {
          email: response.email,
          oauthid: response.id,
          name: response.name,
          oauth: response.accessToken,
          picture: response.picture.data.url,
          type: "facebook",
        };
        login(dataToSend);
      } else {
        console.log("setErrorMessage");
        setErrorMessage(
          "No pudimos ingresar a facebook. Por favor, inténtalo de nuevo"
        );
      }
    } else {
      console.log("setErrorMessage");
      setErrorMessage(
        "No pudimos ingresar a facebook. Por favor, inténtalo de nuevo"
      );
    }
  };

  useEffect(() => {
    console.log("errorMessage sideEffects", errorMessage);
    if (errorMessage !== "") {
      setTimeout(() => {
        setErrorMessage("");
      }, 3500);
    }
  }, [errorMessage]);

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      render={(renderProps) => (
        <div>
          <Button
            onClick={renderProps.onClick}
            className="facebook"
            disabled={renderProps.disabled}
            // prevents an existing Facebook session from logging users in automatically
            autoLoad={false}
          >
            <div>
              {mutation?.isLoading ? <Loader /> : `Ingresar con facebook`}
            </div>
          </Button>
          {errorMessage !== "" ? (
            <div className="facebook-error-msg">{errorMessage}</div>
          ) : null}
        </div>
      )}
      className="facebook"
      redirectUri="https://sonicboxmusic.com"
      disableMobileRedirect={true}
    />
  );
}

/**
 *
 * code=AQAXGNW5xko8Q_Nto8BEMlYWIDgKZQSWiu4VyPqb-ApSX9KieU86bW4-F3HuqyeTBzxJPi1dcI8jNqhfL6492KjLv5pQJBZyISGLptYUUS0W5pV6gn_3YHN_AKraQzlZQnuxZivF3lr9k623XnWXkEQOgdYgngs3a9hp6_pxXBUbYrBNJlvze6b3nFUcXaOrI91DvI_TVnZ9x5BLwTKk1vaTPrLjmRUW1yNPyI3PM8Gdo-cjYX9N43VvIDReKTouSyMiLjfOMITaPEyp3tICjboHNy6lE6-I0CRpc5MxRVim5ljr_x6Y9V2eMLHJw_7uZNgzwyEoCOFmZjombz53pDVwoGmtL-WPz4ScPAlUS71zNp1gUXWpgE-b6aQDPHS_sMw
 * state=facebookdirect#_=_
 */

/**
 * accessToken: "aZ09..."
 * data_access_expiration_time: 1645973663
 * email: "...@...mail.com"
 * expiresIn: 3936
 * graphDomain: "facebook"
 * id: "10221731574659771"
 * name: "John Doe"
 * picture: {data: {
 *  height: 50,
 *  width: 50,
 *  is_silhouette: false,
 *  url: "https://platform-lookaside.fbsbx.com/..."
 * }}
 * signedRequest: "aZ09..."
 * userID: "10221731574659771"
 */
