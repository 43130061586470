import React from "react";
import "./styles/account.scss";
import AccountNavBar from "./AccountNavBar";

const Account = ({ children }) => {
  return (
    <div className="account container">
      <div>{children}</div>
      <AccountNavBar />
    </div>
  );
};

export default Account;
