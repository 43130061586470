import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "react-query";
import axios from "axios";
import { globalHistory, Location, navigate } from "@reach/router";
import { useState } from "react";
import decryptCode from "../utils/decryptCode";

const RadioContext = createContext({
  nameseo: null,
  isDisplay: false,
  isTask: false,
  currentRadioid: () => {},
  setCurrentRadioid: () => {},
});
const UserContext = createContext(null);

export const apiQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

export const API_URL = "https://millsonic.com/api/";

function fetchGet(method, dataToSend) {
  return async () => {
    const { data } = await axios.get(
      API_URL + method + "?" + new URLSearchParams(dataToSend).toString()
    );
    return data;
  };
}

function fetchGetPage(method, initialParams) {
  return async (params) => {
    const sendParams =
      params.pageParam !== undefined ? params.pageParam : initialParams;
    const { data } = await axios.get(
      API_URL + method + "?" + new URLSearchParams(sendParams).toString()
    );
    return data;
  };
}

export const useLocals = (lat, lng, search) => {
  /**
   * @type DataToSend
   * @property {number} limit
   * @property {number} offset
   * @property {number} latitude
   * @property {number} longitude
   * @property {string*} search
   */
  const dataToSend = {
    limit: 100,
    offset: 0,
    latitude: -34.90198799981324,
    longitude: -56.13432873602192,
    search: "",
  };
  if (lat !== undefined && lng !== undefined && lat !== null && lng !== null) {
    dataToSend.latitude = lat; //-34.90198799981324
    dataToSend.longitude = lng; //-56.13432873602192,
  }
  if (search !== "" && search !== undefined) {
    dataToSend.search = search;
  }
  const query = useQuery(
    ["locals", lat, lng, search],
    fetchGet(`locals`, dataToSend),
    {
      enabled:
        (search !== "" && search !== undefined) ||
        (lat !== null &&
          lng !== null &&
          lat !== undefined &&
          lng !== undefined),
    }
  );
  return query;
};

export const useRadioidByNameseo = (nameseo) => {
  const getRadioQuery = useQuery(
    [`getradionameseo`, nameseo],
    async () => {
      const params = `${new URLSearchParams({
        nameseo: nameseo,
      }).toString()}`;
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(`${API_URL}backend-getradionameseo?${params}`)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !!nameseo &&
        nameseo !== undefined &&
        nameseo !== null &&
        typeof nameseo === "string",
      refetchInterval: 5000,
    }
  );

  return {
    ...getRadioQuery,
    radioid: getRadioQuery.data?.radioid ? getRadioQuery.data?.radioid : null,
  };
};

export const useLocal = (radioid) => {
  const { currentRadioid, setCurrentRadioid } = useContext(RadioContext);
  const { token, isKiosk } = useUser();

  useEffect(() => {
    if (radioid !== undefined && currentRadioid !== radioid) {
      setCurrentRadioid(parseInt(radioid));
    }
  }, [radioid, currentRadioid, setCurrentRadioid]);

  const query = useQuery(
    [`singlecola`, parseInt(currentRadioid)],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_URL}singlecola?${new URLSearchParams({
                radioid: currentRadioid,
              }).toString()}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !isKiosk && currentRadioid !== null && currentRadioid !== undefined,
      refetchInterval: 10 * 1000,
    }
  );

  const kioskQuery = useQuery(
    [`singlecola`, token],
    async () => {
      try {
        console.log("-------call singlecola--------", new Date());
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_URL}jukebox-singlecola?${new URLSearchParams({
                token: token,
                callback: "",
              }).toString()}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled: !!isKiosk && token !== null && token !== undefined,
      refetchInterval: 10 * 1000,
    }
  );

  if (isKiosk) {
    return { ...kioskQuery, radioid: kioskQuery?.data?.radioid };
  }
  return {
    ...query,
    radioid: currentRadioid,
  };
};

export const useTerms = (nameseo) => {
  const { user } = useUser();
  const { radioid } = useRadioidByNameseo(nameseo);

  const termsQuery = useQuery(
    ["terms", nameseo, user?.userid],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_URL}getTerms/${nameseo}/${user?.userid}?radioid=${radioid}`,
              {
                headers: {
                  "Content-Type": "text/html",
                },
              }
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !!nameseo &&
        nameseo !== undefined &&
        !!radioid &&
        radioid !== undefined &&
        !!user?.userid,
    }
  );

  const acceptTerms = useMutation(async () => {
    try {
      const data = await new Promise((onResolve, onReject) =>
        axios
          .get(`${API_URL}acceptTerms/${radioid}/${user?.userid}`)
          .then((response) => {
            if (Object.hasOwnProperty.call(response, "status")) {
              if (response.status === 200) {
                onResolve(response.data);
              }
            }
            onReject();
          })
          .catch((error) => {
            console.log("Handle error", error);
            onReject(error);
          })
      );
      return data;
    } catch (e) {
      throw e;
    }
  });
  return { ...termsQuery, acceptTerms: acceptTerms };
};

export const useDisplay = () => {
  const { radioid } = useDisplayRadio();

  const handleGetSingleCola = (data) => {};

  // useEffect(() => {
  //   console.log("useDisplay useEffect change radioid", radioid);
  // }, [radioid]);

  const singlecolaQuery = useQuery(
    [`singlecoladisplay`, parseInt(radioid)],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_URL}singlecola?${new URLSearchParams({
                radioid: radioid,
              }).toString()}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                  handleGetSingleCola(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled: radioid !== null && radioid !== undefined,
      refetchInterval: 10 * 1000,
    }
  );

  return {
    ...singlecolaQuery,
  };
};

export const useDisplayRadio = () => {
  const { nameseo, isDisplay } = useContext(RadioContext);

  // useEffect(() => {
  //   console.log("useDisplay useEffect change radioid", radioid);
  // }, [radioid]);

  const getRadioQuery = useQuery(
    [`getradionameseo`, nameseo],
    async () => {
      try {
        const params = `${new URLSearchParams({
          nameseo: nameseo,
        }).toString()}`;
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(`${API_URL}backend-getradionameseo?${params}`)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        nameseo !== undefined &&
        nameseo !== null &&
        typeof nameseo === "string",
      refetchInterval: 10 * 1000,
    }
  );

  return {
    getRadioQuery: getRadioQuery,
    radioid: getRadioQuery?.data?.radioid,
    nameseo: nameseo,
    isDisplay: isDisplay,
  };
};

export const useNextSong = (radioid) => {
  const { isKiosk } = useUser();
  const { data: localData } = useLocal(radioid);
  const { radioid: displayRadioid } = useDisplayRadio();

  const actualRadioid = useMemo(() => {
    return displayRadioid !== null && displayRadioid !== undefined
      ? displayRadioid
      : isKiosk && localData
      ? localData.radioid
      : radioid;
  }, [displayRadioid, isKiosk, localData, radioid]);

  const query = useQuery(
    [`nextsong`, actualRadioid],
    fetchGet(`nextsong`, {
      // token: token,
      radioid: actualRadioid,
      limit: 100,
      offset: 0,
    }),
    {
      enabled: actualRadioid !== null && actualRadioid !== undefined,
      refetchInterval: 5000,
    }
  );

  return {
    ...query,
    data: query?.data?.data,
  };
};

export const useUser = () => {
  const {
    token,
    isKiosk,
    user,
    setUser,
    login,
    logout,
    userInitiated,
    loginRedirect,
  } = useContext(UserContext);

  const userQuery = useQuery(
    [`user`, token, "user"],
    async () => {
      const { data } = await axios.get(
        API_URL + "user?" + new URLSearchParams({ token: token }).toString()
      );
      setUser({ ...data, token: token, isKiosk: isKiosk });
      return data;
    },
    { enabled: token !== null && !isKiosk }
  );

  const kioskUserQuery = useQuery(
    [`user`, token, "kiosk"],
    async () => {
      const { data } = await axios.get(
        API_URL +
          "jukebox-user?" +
          new URLSearchParams({ token: token, callback: "" }).toString()
      );
      setUser({ ...data, token: token, isKiosk: isKiosk });
      return data;
    },
    { enabled: token !== null && !!isKiosk }
  );

  // Define the "login" mutation
  apiQueryClient.setMutationDefaults("login", {
    mutationFn: async (dataToSend) => {
      let url = `${API_URL}login`;
      if (dataToSend?.type === "facebook" || dataToSend?.type === "google") {
        url = `${API_URL}addUser`;
      }
      console.log("login", url, dataToSend);
      const { data } = await axios.post(url, dataToSend);
      return data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      if (result !== null && result.hasOwnProperty("token")) {
        // Replace optimistic todo in the user list with the result
        apiQueryClient.setQueryData("login", (old) => result);
        result.isKiosk = false;
        login(result.token, result);
      }
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on login error");
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("login", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("login");
  const loginData = mutation.data;

  const updateUser = () => {
    apiQueryClient.invalidateQueries("user");
  };

  return {
    mutation: mutation,
    userQuery: isKiosk ? kioskUserQuery : userQuery,
    login: mutation.mutateAsync,
    logout: logout,
    loginData: loginData,
    user: user,
    isKiosk: isKiosk,
    token: token,
    logged: token !== null,
    initiated: userInitiated,
    updateUser: updateUser,
    loginRedirect: loginRedirect,
  };
};

export const useFavorites = () => {
  const [mutatingObserver, setMutatingObserver] = useState(false);
  const { token } = useUser();

  const favoritesQuery = useQuery(
    ["favorites", token],
    fetchGet(
      "favorites",
      {
        token: token,
        action: "list",
      },
      { enabled: token !== null }
    )
  );

  // Define the "addfavorite" mutation
  apiQueryClient.setMutationDefaults("addfavorite", {
    mutationFn: async (dataToSend) => {
      const { data } = await axios.post(`${API_URL}favorites`, {
        ...dataToSend,
        token: token,
      });
      return data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      updateFavorites();
      setMutatingObserver(true);
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on addfavorite error");
      updateFavorites();
      setMutatingObserver(true);
    },
    retry: 1,
  });

  const mutation = useMutation("addfavorite");

  const updateFavorites = () => {
    apiQueryClient.invalidateQueries("favorites");
  };

  if (!favoritesQuery.isFetching && mutatingObserver) {
    setMutatingObserver(false);
  }

  return {
    ...favoritesQuery,
    mutation: mutation,
    isMutating: mutation.isLoading || mutatingObserver,
    addFavorite: (songid) => {
      mutation.mutateAsync({ action: "add", songid: songid });
    },
    removeFavorite: (songid) => {
      mutation.mutateAsync({ action: "remove", songid: songid });
    },
    updateFavorites: updateFavorites,
  };
};

/**
 * Creates a react-query query for calling "songlist"
 * @param {string} playlistid
 * @param {string} search
 * @param {number} offset
 * @returns {object}
 */
export const useSongs = (playlistid, search, radioid) => {
  const { token, isKiosk } = useUser();
  const { data: localData } = useLocal(radioid);
  const SONGS_PER_PAGE = 40;

  const dataToSend = {
    token: token,
    offset: 0,
    limit: SONGS_PER_PAGE,
    radioid: isKiosk ? localData.radioid : radioid,
  };

  if (search !== "") {
    dataToSend.filter = search;
  } else if (playlistid !== null) {
    dataToSend.playlistid = playlistid;
  }

  const songsQuery = useInfiniteQuery(
    ["songlist", token, dataToSend.radioid, playlistid, search],
    fetchGetPage("songlist", dataToSend, { enabled: token !== null }),
    {
      getNextPageParam: (lastPage, pages) => {
        // console.log("getNextPageParam", lastPage, pages);
        const pageParams = {
          token: token,
          offset: pages.length * SONGS_PER_PAGE,
          limit: SONGS_PER_PAGE,
          radioid: dataToSend.radioid,
        };
        if (search !== "") {
          pageParams.filter = search;
        } else if (playlistid !== null) {
          pageParams.playlistid = playlistid;
        }
        return pageParams;
      },
      enabled: !!dataToSend.radioid,
      refetchInterval: 20000,
    }
  );

  return {
    songs: songsQuery?.data,
    total: songsQuery?.data?.pages[0].total,
    loadMore: songsQuery.fetchNextPage,
    ...songsQuery,
  };
};

export const useVote = (radioid) => {
  const { user, isKiosk } = useUser();
  const { token } = user;
  const { data: localData } = useLocal(radioid);

  // Define the "vote" mutation
  apiQueryClient.setMutationDefaults("vote", {
    retries: 1,
    mutationFn: async (params) => {
      if (params.scid !== undefined) {
        const dataToSend = {
          token: token,
          votes: params.votes,
          scid: params.scid,
        };
        try {
          const response = await axios.post(`${API_URL}votesong`, dataToSend);
          return response.data;
        } catch (error) {
          console.log("vote error catch", error.response);
        }
      } else {
        const dataToSend = {
          token: token,
          radioid: isKiosk ? localData.radioid : radioid,
          votes: params.votes,
          songid: params.songid,
        };
        const response = await axios.post(`${API_URL}requestsong`, dataToSend);
        return response.data;
      }
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("vote", (old) => result);
      apiQueryClient.invalidateQueries(["singlecola", parseInt(radioid)]);
      apiQueryClient.invalidateQueries(["user"]);
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on vote error", error, error.response, variables, context);
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("vote", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("vote");

  const vote = (params) => {
    mutation.mutateAsync(params);
  };

  const voteData = mutation.data;

  return {
    mutation: mutation,
    vote: vote,
    voteData: voteData,
  };
};

export const useCode = () => {
  const { user, logged } = useUser();
  const token = logged ? user?.token : null;

  // Define the "colaCode" mutation
  apiQueryClient.setMutationDefaults("colaCode", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        ccode: params.ccode,
      };
      const response = await axios.post(
        `${API_URL}validateColaCode`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("colaCode", (old) => result);
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on colaCode error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("colaCode", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("colaCode");

  const sendCode = useCallback(
    async (
      params,
      successCallback = () => {},
      errorCallback = () => {},
      doneCallback = () => {}
    ) => {
      try {
        const codeResponse = await mutation.mutateAsync(params);
        if (codeResponse !== undefined) {
          successCallback(codeResponse);
        }
      } catch (error) {
        console.error("sendcode error", error);
        errorCallback(error);
      } finally {
        doneCallback();
      }
    },
    [mutation]
  );

  const codeData = mutation.data;

  return {
    mutation: mutation,
    sendCode: sendCode,
    codeData: codeData,
  };
};

export const useKioskLogin = () => {
  const { login } = useContext(UserContext);

  // Define the "kiosk-login" mutation
  apiQueryClient.setMutationDefaults("kiosk-login", {
    mutationFn: async (params) => {
      const dataToSend = {
        callback: "",
        code: params.code,
      };
      const response = await axios.post(`${API_URL}jukebox-login`, dataToSend);
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      console.log("on kiosk-login success", result);
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("kiosk-login", (old) => result);
      result.isKiosk = true;
      if (result.hasOwnProperty("token")) {
        login(result.token, result);
      }
      console.log(result);
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on kiosk-login error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("kiosk-login", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("kiosk-login");

  const sendCode = async (params, callback = () => {}) => {
    try {
      const codeResponse = await mutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const codeData = mutation.data;

  return {
    mutation: mutation,
    sendCode: sendCode,
    codeData: codeData,
  };
};

export const useCards = () => {
  const { user } = useUser();
  const { token } = user;

  const cardsQuery = useQuery(
    ["cards", token],
    fetchGet("cards", { token: token }),
    { enabled: token !== null }
  );

  const updateCards = () => {
    apiQueryClient.invalidateQueries("cards");
  };

  // Define the "addCard" mutation
  apiQueryClient.setMutationDefaults("addCard", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        name: params.name,
        card_num: params.card_num,
        exp_month: params.exp_month,
        exp_year: params.exp_year,
        stripeToken: params.stripeToken,
        cvc: params.cvc,
      };
      const response = await axios.post(`${API_URL}addCard`, dataToSend);
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      console.log("on addCard success", result);
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("addCard", (old) => result);
      apiQueryClient.invalidateQueries("cards");
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on addCard error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("addCard", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const addCardMutation = useMutation("addCard");

  const addCard = async (params, callback = () => {}) => {
    console.log("useApi addCard ", params);
    try {
      const codeResponse = await addCardMutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const addCardData = addCardMutation.data;

  // Define the "deleteCard" mutation
  apiQueryClient.setMutationDefaults("deleteCard", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        ucid: params.ucid,
      };
      const response = await axios.post(`${API_URL}deletecard`, dataToSend);
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("deleteCard", (old) => result);
      apiQueryClient.invalidateQueries("cards");
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on deleteCard error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("deleteCard", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const deleteCardMutation = useMutation("deleteCard");

  const deleteCard = async (params, callback = () => {}) => {
    try {
      const codeResponse = await deleteCardMutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const deleteCardData = deleteCardMutation.data;

  return {
    cardsQuery: cardsQuery,
    cards: cardsQuery.data?.cards,
    isLoading: cardsQuery.isLoading,
    isError: cardsQuery.isError,
    updateCards: updateCards,
    error: cardsQuery.error,
    addCardMutation: addCardMutation,
    addCard: addCard,
    addCardData: addCardData,
    deleteCardMutation: deleteCardMutation,
    deleteCard: deleteCard,
    deleteCardData: deleteCardData,
  };
};

export const ApiProvider = ({ children }) => {
  const [currentRadioid, setCurrentRadioid] = useState(null);
  const [user, setUser] = useState(null);
  const [initiated, setInitiated] = useState(false);
  const [token, setToken] = useState(null);
  const [isKiosk, setIsKiosk] = useState(null);
  const isBrowser = useCallback(() => typeof window !== "undefined", []);

  const loginRedirect = isKiosk ? "/kiosk" : "/sonicboxes";

  const saveUser = useCallback((user) => {
    window.localStorage.setItem("sonicboxUser", JSON.stringify(user));
  }, []);

  const getUser = useCallback(() => {
    if (isBrowser() && window.localStorage.getItem("sonicboxUser")) {
      const storedUser = JSON.parse(
        window.localStorage.getItem("sonicboxUser")
      );
      // console.log("storedUser", storedUser)
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        return storedUser;
      }
    }
    return { token: null, name: "" };
  }, [isBrowser]);

  useEffect(() => {
    if (user === null) {
      const storedUser = getUser();
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        setUser(storedUser);
        setToken(storedUser.token);
        setIsKiosk(storedUser.isKiosk);
      }
    } else {
      console.log("save user", user, "isKiosk", isKiosk);
      user.isKiosk = isKiosk === true;
      saveUser(user);
    }
    setInitiated(true);
  }, [user, isKiosk, getUser, saveUser]);

  const login = useCallback((newToken, newUser) => {
    setToken(newToken);
    setUser(newUser);
    setIsKiosk(newUser.isKiosk);
    setTimeout(() => {
      navigate(newUser.isKiosk ? "/kiosk" : "/sonicboxes");
    }, 500);
  }, []);

  const logout = useCallback(
    (callback) => {
      apiQueryClient.removeQueries(["user", token]);
      console.log("logout");
      const navigateToKiosk = isKiosk;
      console.log(window.FB);
      if (window?.FB) {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        window.FB.api("/me/permissions", "delete", null, (...args) => {
          console.log("FB delete permissions callback", args);
          window.FB.logout();
        });
      }
      setUser({ token: null, name: "", isKiosk: null });
      saveUser("");
      setToken(null);
      setIsKiosk(null);
      if (navigateToKiosk) {
        navigate("/kiosk");
      } else {
        navigate("/");
      }
      if (callback) callback();
    },
    [token, setUser, setToken, saveUser, isKiosk]
  );

  useEffect(() => {
    globalHistory.listen(({ pathname }) => {
      console.log("pathname", pathname);
    });
  }, []);

  return (
    <Location>
      {({ location }) => {
        const matchedRadio = location.pathname.match(/\/sonicbox\/(.+)/);
        const radioNameseo =
          matchedRadio !== null ? parseInt(matchedRadio[1]) : null;

        const matchedNameseo = location.pathname.match(/\/display\/(.+)/);
        const displayNameseo =
          matchedNameseo !== null ? matchedNameseo[1] : null;
        const isDisplay = displayNameseo !== null;

        const matchedTaskNameseo = location.pathname.match(/\/task\/(.+)/);
        const taskNameseo =
          matchedTaskNameseo !== null ? matchedTaskNameseo[1] : null;
        const isTask = taskNameseo !== null;

        return (
          <RadioContext.Provider
            value={{
              nameseo: isDisplay
                ? displayNameseo
                : isTask
                ? taskNameseo
                : radioNameseo,
              isDisplay: isDisplay,
              isTask: isTask,
              currentRadioid: currentRadioid,
              setCurrentRadioid: setCurrentRadioid,
            }}
          >
            <UserContext.Provider
              value={{
                user: user,
                setUser: setUser,
                token: token,
                userInitiated: initiated,
                setToken: setToken,
                login: login,
                logout: logout,
                isKiosk: isKiosk,
                loginRedirect: loginRedirect,
              }}
            >
              <QueryClientProvider client={apiQueryClient}>
                <StoredCodesProvider>{children}</StoredCodesProvider>
              </QueryClientProvider>
            </UserContext.Provider>
          </RadioContext.Provider>
        );
      }}
    </Location>
  );
};

const StoredCodesProvider = ({ children }) => {
  const isBrowser = useCallback(() => typeof window !== "undefined", []);
  const { logged } = useUser();
  const { sendCode } = useCode();
  const [sentCode, setSentCode] = useState(null);

  const removeStoredCode = useCallback(
    (storedCode) => {
      const currStored =
        isBrowser() && window.localStorage.getItem("sonicbox-codes")
          ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
          : [];
      console.log("currStored", currStored, storedCode);
      const storedCodeIndex = currStored.indexOf(storedCode);
      console.log(storedCodeIndex);
      if (storedCodeIndex > -1) {
        currStored.splice(storedCodeIndex, 1);
        if (isBrowser) {
          window.localStorage.setItem(
            "sonicbox-codes",
            JSON.stringify(currStored)
          );
        }
        setSentCode(null);
      }
    },
    [isBrowser]
  );

  useEffect(() => {
    const storedCodes =
      isBrowser() && window.localStorage.getItem("sonicbox-codes")
        ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
        : [];
    console.log("useEffect in useCode", storedCodes);
    if (logged && storedCodes.length > 0) {
      const firstCode = storedCodes[0];
      console.log(storedCodes);
      const splitted = decryptCode(firstCode).split("-");
      if (splitted.length > 0) {
        const codeToSend = splitted[0];
        if (codeToSend.length > 0 && sentCode !== codeToSend) {
          setSentCode(codeToSend);
          sendCode(
            { ccode: codeToSend },
            (response) => {
              console.log("response", response);
              if (
                response.hasOwnProperty("status") &&
                response.status === "success"
              ) {
                removeStoredCode(firstCode);
                setSentCode(null);
              }
            },
            (error) => {
              console.log("sendcode error", error);
              if (error.hasOwnProperty("response")) {
                console.log("error response", error.response);
                if (error.response?.data?.msg === "Code does not work") {
                  removeStoredCode(firstCode);
                  setSentCode(null);
                  alert("El código ya no es válido");
                }
              }
            }
          );
        }
      }
    }
  }, [logged, sendCode, sentCode, removeStoredCode, isBrowser]);
  return children;
};

export const getNameseoByRadioid = async (radioid) => {
  const response = await axios.post(`${API_URL}singlecola`, { radioid });
  if (response?.data?.nameseo) {
    return response.data.nameseo;
  }
  return null;
};
