import React, { useEffect } from "react";

const PublicRoute = ({ component: Component, location, ...rest }) => {
  useEffect(() => {
    console.log("PublicRoute", location.pathname);
  }, [location, rest]);

  return <Component {...rest} />;
};

export default PublicRoute;
