import React, { useEffect, useMemo, useRef, useState } from "react";
import { useKioskLogin, useLocal, useUser } from "../../../hooks/useApi";
import CodeWidget from "../../basic/CodeWidget";
import Loader from "../../basic/Loader";
import Sonicbox from "../sonicbox";
import "./styles/kiosk.scss";
import { KIOSK_CODE_LENGTH } from "../../../constants";

const Kiosk = () => {
  const [code, setCode] = useState("");
  const { mutation, sendCode } = useKioskLogin();
  const { logged, isKiosk } = useUser();
  const sentCode = useRef();

  const { isLoading, data: localData, isError, error } = useLocal();

  useEffect(() => {
    console.log("code", code, "current", sentCode.current);
    console.log("code", typeof code, "current", typeof sentCode.current);
    if (code.length === KIOSK_CODE_LENGTH && sentCode.current !== code) {
      sentCode.current = code;
      console.log("sendCode", { code: code });
      mutation.reset();
      sendCode(
        {
          code: code,
        },
        () => {
          console.log("trigger Callback");
          setCode("");
        }
      );
    }
  }, [code, sentCode, sendCode, mutation]);

  const errorMessage = useMemo(() => {
    let message = mutation.isError ? mutation.error.response.data.msg : "";
    switch (message) {
      case "Code does not work":
        return "Código inválido";
      default:
        return message;
    }
  }, [mutation]);

  return (
    <div className="kiosk container">
      {logged && isKiosk ? (
        isLoading ? (
          <Loader />
        ) : isError ? (
          <p className="error">{error}</p>
        ) : (
          <Sonicbox radioid={localData.radioid} />
        )
      ) : (
        <CodeWidget
          value={code}
          onChange={setCode}
          codeLength={KIOSK_CODE_LENGTH}
          isLoading={mutation.isLoading}
          isError={mutation.isError}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default Kiosk;
