import React, { useMemo } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-success.scss";
import Loader from "../basic/Loader";
import { useLocal } from "../../hooks/useApi";

const AddCreditSuccessModal = ({ credits }) => {
  const { data: localData } = useLocal();

  const showModal = useMemo(
    () => localData?.name.indexOf("El Corral") === -1,
    [localData]
  );

  if (!showModal) return null;

  return (
    <Modal className="modal-success add-credit-success">
      {credits ? (
        <h2>
          <span>¡Genial!</span>
          <small>Ya tienes</small>
          <strong>{credits}</strong>
          <small>créditos nuevos</small>
        </h2>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default AddCreditSuccessModal;
