import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  ADD_CREDITS_SUCCESS_MODAL,
  TERMS_AND_CONDITIONS_MODAL,
  VOTED_SUCCESS_MODAL,
} from "../../../constants";
import { scrollIntoView } from "../../../utils/domManipulation";
import { useModal } from "../../../hooks/useModal";
import usePrevious from "../../../hooks/usePrevious";
import {
  useLocal,
  useRadioidByNameseo,
  useTerms,
  useUser,
} from "../../../hooks/useApi";
import { useScrollObserver } from "../../../hooks/useScrollObserver";

import DevPre from "../../dev/DevPre";

import LaggedInput from "../../basic/LaggedInput";
import Button from "../../basic/Button";
import Loader from "../../basic/Loader";

import Sticky from "../../layout/Sticky";

import Boxes from "./components/Boxes";
import NowPlaying from "./components/NowPlaying";
import NextPlaying from "./components/NextPlaying";
import Songs from "./components/Songs";

import "./styles/sonicbox.scss";
import MenuToggleSVG from "../../../inline-svg/menu-toggle.svg";

const Sonicbox = ({ nameseo }) => {
  useEffect(() => {
    if (typeof nameseo !== "undefined") {
      window.location.href = `https://millsonicbox.com/sonicbox/${nameseo}`;
    }
  }, [nameseo]);

  const { stackModal, lastModal } = useModal(ADD_CREDITS_SUCCESS_MODAL);
  const {
    openModal: openTermsModal,
    currentModal,
    closeModal,
  } = useModal(TERMS_AND_CONDITIONS_MODAL);
  const [openPeekTerms, setOpenPeekTerms] = useState(false);
  const termsQuery = useTerms(nameseo);

  const { user, isKiosk } = useUser();
  const { points } = user;
  const previousPoints = usePrevious(points);

  const headerRef = useRef();
  const musicRef = useRef();
  const boxesRef = useRef();
  const stickyBoxesRef = useRef();
  const searchInputRef = useRef();
  const songsWrapperRef = useRef();

  const { ref: songsRef, inView } = useScrollObserver("songs");
  const [selectedParent, setParent] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [search, setSearch] = useState("");

  const { radioid } = useRadioidByNameseo(nameseo);

  const {
    isLoading,
    data,
    isError,
    error,
    radioid: currentRadioid,
  } = useLocal(radioid);

  useEffect(() => console.log("local radioid", radioid), [radioid]);

  useEffect(() => {
    console.log("local currentRadioid", currentRadioid, data);
    if (
      data?.name &&
      (data.name.indexOf("Falabella") > -1 ||
        data.name.indexOf("falabella") > -1)
    ) {
      console.log("is falabella!!!");
      if (document) {
        document.body.classList.add("falabella");
        console.log("add class falabella");
      }
    } else {
      if (document) {
        document.body.classList.remove("falabella");
        console.log("remove class falabella");
      }
    }
    if (data?.name && data.name.indexOf("Casper") > -1) {
      console.log("is casper!!!");
      if (document) {
        document.body.classList.add("casper");
      }
    } else {
      if (document) {
        document.body.classList.remove("casper");
      }
    }
  }, [currentRadioid, data]);

  useEffect(() => {
    if (lastModal === VOTED_SUCCESS_MODAL) {
      console.log("----------- VOTED_SUCCESS_MODAL ------------");
      scrollIntoView(headerRef.current);
    }
  }, [lastModal]);

  // useEffect(() => {
  //   if (data?.radiobg && data.radiobg !== "") {
  //     document.body.style.backgroundImage = `url(${data.radiobg})`;
  //   }
  //   return () => (document.body.style.backgroundImage = null);
  // }, [data]);

  //const { isLoading, data, isError, error } = useLocal(radioid, null);

  const handleLetsPlay = () => {
    if (musicRef.current !== undefined) {
      scrollIntoView(musicRef.current, 75);
    }
  };

  const handleOnSelectBox = (selectedBox) => {
    setSearch("");
    setSelectedBox(selectedBox);
    console.log(
      "scrollIntoView songsWrapperRef",
      songsWrapperRef,
      songsWrapperRef.current
    );
    if (songsWrapperRef.current !== undefined) {
      scrollIntoView(songsWrapperRef.current, 160);
    }
  };

  const handleScrollBack = () => {
    handleLetsPlay();
  };

  const handleOnClickInput = useCallback(
    (event) => {
      event.preventDefault();
      setTimeout(() => {
        if (searchInputRef.current !== undefined) {
          event.target.blur();
          searchInputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            searchInputRef.current
              .getElementsByTagName("input")[0]
              .focus({ preventScroll: false });
          }, 500);
          console.log("focus on other ref", searchInputRef.current);
        }
      }, 500);
      return false;
    },
    [searchInputRef]
  );

  useEffect(() => {
    const pointsInt = parseInt(points);
    const prePointsInt = parseInt(previousPoints);
    if (pointsInt > prePointsInt) {
      stackModal({ credits: pointsInt - prePointsInt });
    }
  }, [points, previousPoints, stackModal]);

  useEffect(() => {
    if (termsQuery?.data) {
      console.log("currentModal", currentModal, TERMS_AND_CONDITIONS_MODAL);
      if (
        !termsQuery.data.accepted &&
        currentModal !== TERMS_AND_CONDITIONS_MODAL
      ) {
        console.log("openTermsModal", nameseo);
        setTimeout(() => {
          console.log("openTermsModal", nameseo);
          openTermsModal({ nameseo: nameseo });
        }, 3000);
      }
      if (
        !!termsQuery.data.accepted &&
        currentModal === TERMS_AND_CONDITIONS_MODAL &&
        !openPeekTerms
      ) {
        console.log("now close terms modal");
        closeModal();
      }
    }
  }, [
    termsQuery.data,
    nameseo,
    openTermsModal,
    currentModal,
    closeModal,
    openPeekTerms,
  ]);

  const selectedPlaylistName = useMemo(() => {
    return data?.playlists?.find(
      (value) => parseInt(value.playlistid) === parseInt(selectedBox)
    )?.playlistname;
  }, [selectedBox, data]);

  return (
    <div className="container">
      {isError ? (
        <DevPre style={{ color: "red" }}>
          {JSON.stringify(error, null, " ")}
        </DevPre>
      ) : isLoading ? (
        <Loader />
      ) : (
        <div className={["sonicbox", isKiosk ? "kiosk" : ""].join(" ")}>
          <header
            id="header"
            style={{
              backgroundImage: data?.radiobg ? `url(${data.radiobg})` : "",
            }}
            ref={headerRef}
          >
            <NowPlaying {...data} radioid={radioid} />
            <Button className="accent" size={"big"} onClick={handleLetsPlay}>
              Elige la siguiente canción
            </Button>
            <LaggedInput
              value={search}
              onChange={setSearch}
              placeholder="Buscar canción"
              containerClassName="search-song-input"
              deleteBtn={true}
              onClick={handleOnClickInput}
            />
            <NextPlaying {...data} />
            {termsQuery?.data && (
              <Button
                className="brand"
                as="link"
                onClick={() => {
                  setOpenPeekTerms(true);
                  openTermsModal({
                    nameseo: nameseo,
                    isPeeking: true,
                    setIsPeeking: setOpenPeekTerms,
                  });
                }}
                style={{
                  marginLeft: "auto",
                  display: "block",
                  marginTop: 20,
                  marginBottom: -14,
                  marginRight: -8,
                  fontSize: 12,
                  opacity: 0.5,
                }}
              >
                Términos y condiciones
              </Button>
            )}
          </header>
          <h2 ref={musicRef}>{`Elige cómo sigue la música`}</h2>
          <Sticky top={64} className="browse-tools" triggerRef={searchInputRef}>
            {search === "" ? (
              <div id="boxes">
                <Boxes
                  ref={stickyBoxesRef}
                  boxesRef={boxesRef}
                  songsRef={songsRef}
                  playlists={data ? data.playlists : []}
                  parents={data ? data.parents : []}
                  onSelectBox={handleOnSelectBox}
                  selectedBox={selectedBox}
                  boxesOffset={80}
                  songsOffset={180}
                  selectedParent={selectedParent}
                  setParent={setParent}
                  isKiosk={isKiosk}
                />
              </div>
            ) : null}
            <LaggedInput
              value={search}
              onChange={setSearch}
              placeholder="Buscar canción"
              containerClassName="search-song-input"
              deleteBtn={true}
            />
          </Sticky>
          {search === "" ? (
            <div id="boxes">
              <Boxes
                ref={boxesRef}
                playlists={data ? data.playlists : []}
                parents={data ? data.parents : []}
                onSelectBox={handleOnSelectBox}
                selectedBox={selectedBox}
                boxesRef={boxesRef}
                songsRef={songsRef}
                boxesOffset={80}
                songsOffset={180}
                selectedParent={selectedParent}
                setParent={setParent}
                isKiosk={isKiosk}
              />
            </div>
          ) : null}
          <LaggedInput
            ref={searchInputRef}
            value={search}
            onChange={setSearch}
            placeholder="Buscar canción..."
            containerClassName="search-song-input"
            deleteBtn={true}
            withKeyboard={true}
          />
          <div ref={songsWrapperRef}>
            <div id="songs" ref={songsRef}>
              <Songs
                playlistid={selectedBox}
                playlistname={selectedPlaylistName}
                search={search}
                setSearch={setSearch}
                radioid={radioid}
              />
            </div>
          </div>
        </div>
      )}
      <Button
        className={["blue", "scroll-back-button", inView ? "show" : ""].join(
          " "
        )}
        onClick={handleScrollBack}
      >
        <img src={MenuToggleSVG} alt="ir arriba" />
      </Button>
    </div>
  );
};

export default Sonicbox;
