import React, { useMemo } from "react";
import Button from "./basic/Button";
import "../styles/credit-card.scss";
import { useCards } from "../hooks/useApi";
import Loader from "./basic/Loader";

export const example = {
  ucid: "28",
  userid: "37",
  last: "4034",
  exp: "04/23",
  stripeId: "cus_KJM3DPH5uO07eq",
  cardtoken: "card_0JejLbzWzPttd7w8KIObiVD1",
  name: "Ignacio Miller Sureda",
  brand: "Visa",
  status: "1",
};

const CreditCard = ({
  ucid,
  userid,
  last,
  exp,
  stripeId,
  cardtoken,
  name,
  brand,
  status,
  onClick,
  enableDelete,
  onDelete,
  selected,
}) => {
  const { deleteCard, deleteCardMutation } = useCards();
  const isDeleting = deleteCardMutation.isLoading;

  const handleOnClick = () => {
    onClick();
  };

  const handleOnDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    deleteCard(ucid);
    if (typeof onDelete === "function") {
      onDelete(ucid);
    }
  };

  const shortNumber = `${brand} **** ${last}`;

  const expired = useMemo(() => {
    const split = exp.split("/");
    const month = parseInt(split[0]);
    const year = 2000 + parseInt(split[1]);
    const date = new Date();
    date.setMonth(month);
    date.setFullYear(year);
    return date.getTime() < new Date().getTime();
  }, [exp]);

  return (
    <div
      className={[
        "credit-card",
        selected ? "selected" : "",
        expired ? "expired" : "",
      ].join(" ")}
      onClick={() => handleOnClick()}
    >
      {enableDelete ? (
        <Button
          onClick={(event) => handleOnDelete(event)}
          disabled={isDeleting}
          className="button-delete"
        >
          {isDeleting ? <Loader /> : <span>&times;</span>}
        </Button>
      ) : null}
      <span className="number">{shortNumber}</span>
      <span className="expiration">{`Venc. ${exp}`}</span>
      {selected ? <img src="" alt="tick" className="tick" /> : null}
    </div>
  );
};

export default CreditCard;
