import React, { useEffect, useMemo, useState } from "react";

import { useLocals } from "../../../../hooks/useApi";
import useGeoLocation from "../../../../hooks/useGeoLocation";

import Loader from "../../../basic/Loader";
import Button from "../../../basic/Button";
import LaggedInput from "../../../basic/LaggedInput";
import DevPre from "../../../dev/DevPre";

import SingleLocal from "./SingleLocal";

import "./../styles/sonicboxes.scss";
import MapLoadingPNG from "../../../../images/map_loading.png";
import SearchPNG from "../../../../images/search.png";

export default function Locales({ onEnter, buttonLabel }) {
  const [expand, setExpand] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchExpanded, setSearchExpanded] = useState(false);

  const {
    isLoading: loadingLocation,
    error: errorLocation,
    lat,
    lng,
  } = useGeoLocation();

  const {
    data: locals,
    isLoading,
    isError,
    error,
  } = useLocals(lat, lng, search);

  useEffect(() => {
    if (expand === true && search !== "") {
      setSearchExpanded(true);
    }
    if (search !== "") {
      setExpand(true);
    }
    if (expand === true && search === "" && searchExpanded) {
      setSearchExpanded(false);
      setExpand(false);
    }
  }, [expand, search, searchExpanded]);

  const filteredLocals = useMemo(() => {
    if (locals === undefined) {
      return [];
    }
    if (locals.length > 1) {
      if (!expand) {
        return [locals[0]];
      }
    }
    return locals;
  }, [locals, expand]);

  const expandedSearch = useMemo(() => {
    return errorLocation !== null || expandSearch;
  }, [expandSearch, errorLocation]);

  return (
    <>
      {isError ? (
        <DevPre>errorFetching {JSON.stringify(error, null, " ")}</DevPre>
      ) : null}
      {errorLocation !== null ? (
        <DevPre>
          errorLocation {JSON.stringify(errorLocation, null, " ")}
        </DevPre>
      ) : null}
      <div className="search-wrapper">
        {expandedSearch ? (
          <LaggedInput
            value={search}
            onChange={setSearch}
            placeholder="Buscar"
            containerClassName="search-container"
          />
        ) : null}
        {expandedSearch ? (
          <Button
            type="button"
            onClick={() => {
              setSearch("");
              setExpandSearch(!expandSearch);
            }}
            as="link"
            className="search-btn"
          >
            &times;
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => {
              setExpandSearch(!expandSearch);
            }}
            as="link"
            className="search-btn"
          >
            <img src={SearchPNG} alt="Buscar" className="icon" />
            {`buscar`}
          </Button>
        )}
      </div>
      {search === "" && loadingLocation ? (
        <>
          <p className="main-msg">Estamos buscando boxes cercanos</p>
          <img src={MapLoadingPNG} alt="Cargando..." className="map-loading" />
        </>
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : errorLocation !== null &&
            (search === "" || search === undefined) ? (
            <p>
              No hemos podido acceder a tu ubicación. <br />
              Igual puedes buscar Sonicboxes por su nombre
            </p>
          ) : isError ? (
            <p>
              Hubo un problema recibiendo los datos. Por favor inténtalo más
              tardde.
            </p>
          ) : locals !== null && locals !== undefined ? (
            <>
              <p className="main-msg">
                {locals.length === 0
                  ? `No encontramos ninguna Sonicbox ${
                      search === "" ? "cerca de tí" : ` con "${search}"`
                    }`
                  : search === ""
                  ? `¿Estás en ${locals[0].name}?`
                  : `Resultados para "${search}"`}
              </p>
              <ul className="locals-container">
                {filteredLocals.map((local, index) => (
                  <SingleLocal
                    key={index.toString()}
                    {...local}
                    onClick={onEnter}
                    label={
                      typeof buttonLabel === "string"
                        ? buttonLabel
                        : typeof buttonLabel === "function"
                        ? buttonLabel(local)
                        : "Entrar"
                    }
                  />
                ))}
              </ul>
              {!expand && locals.length > 1 ? (
                <p>
                  ¿No se lo que buscabas?{" "}
                  <Button
                    onClick={() => setExpand(true)}
                    as="link"
                    className="brand"
                  >
                    Ver más
                  </Button>
                </p>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </>
  );
}
