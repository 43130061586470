import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const Sticky = (props) => {
  const { top, triggerRef } = props;
  const [sticked, setSticked] = useState(false);
  const containerRef = useRef();
  const stickyRef = useRef();
  const listenerRef = useRef(null);

  useEffect(() => {
    if (listenerRef.current === null || listenerRef.current === undefined) {
      listenerRef.current = window.addEventListener("scroll", (event) => {
        if (
          triggerRef !== null &&
          triggerRef.current !== null &&
          triggerRef.current !== undefined &&
          containerRef !== null &&
          containerRef.current !== null &&
          containerRef.current !== undefined &&
          stickyRef.current !== null &&
          stickyRef.current !== undefined
        ) {
          const dif = triggerRef.current.offsetTop - window.scrollY - top;
          // console.log(triggerRef.current.offsetTop, window.scrollY, dif);
          if (dif < 1) {
            setSticked(true);
          } else {
            setSticked(false);
          }
        }
      });
    }
    return () => {
      window.removeEventListener("scroll", listenerRef.current);
      listenerRef.current = null;
    };
  }, [containerRef, stickyRef, listenerRef, top, triggerRef]);

  return (
    <div className="sticky-wrapper" ref={containerRef}>
      <div
        ref={stickyRef}
        className={[
          "sticky",
          sticked ? "sticked" : "not-sticked",
          props.className ? props.className : "",
        ].join(" ")}
        style={{
          position: sticked ? "fixed" : "",
          top: top,
          ...props?.style,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Sticky;
