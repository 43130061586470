import React from "react";
import { useFavorites } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";
import DevPre from "../../dev/DevPre";
import Song from "../sonicbox/components/Song";

const Favorites = () => {
  const { data, isLoading, isError, error } = useFavorites();

  return (
    <div className="favorites">
      <h1>Mis canciones</h1>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <DevPre>{JSON.stringify(error, null, " ")}</DevPre>
      ) : (
        <div className="songs favorites">
          {data.map((song) => (
            <Song key={song.songid} {...song} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Favorites;
