import seedrandom from "seedrandom";

function colorFromString(seed) {
  const rng = seedrandom(seed + "hellos");
  const randomNumber = rng();
  const rangeInt = Math.floor(randomNumber * 256 * 256 * 256);
  console.log("randomNumber", randomNumber);
  console.log("rangeInt", rangeInt);
  const red = rangeInt % 256;
  const green = ((rangeInt - red) / 256) % 256;
  const blue = Math.floor((rangeInt - red - green) / (256 * 256)) % 256;
  const rgb = `rgb(${red}, ${green}, ${blue})`;
  console.log(rgb);
  const luminosity = (red * 299 + green * 587 + blue * 114) / 1000;
  console.log("luminosity", luminosity);
  const textColor = luminosity > 128 ? "black" : "white";
  return { main: rgb, textColor: textColor };
}

export default colorFromString;
