import IconTempoUpSVG from "../images/tempo-up.svg";
import IconTempoMidUpSVG from "../images/tempo-midup.svg";
import IconTempoMidSVG from "../images/tempo-mid.svg";
import IconTempoDownSVG from "../images/tempo-down.svg";

export default function cleanBoxName(playlistname, text = false) {
  if (typeof playlistname === "string" && playlistname !== "") {
    // console.log("cleanBoxName", playlistname);
    /**
     * Parse playlist names to remove the "(Up)", "(Mid)", "(Down)" strings
     * and replace them with an icon.
     * It also cleans the name from prefixes like "- ", "X "
     */
    let cleanName = playlistname;

    /**
     * removes "- " from the begining of the name
     */
    if (cleanName.indexOf("- ") === 0) {
      cleanName = cleanName.replace("- ", "");
    }

    /**
     * removes "X " from the begining of the name
     */
    if (cleanName.indexOf("X ") === 0) {
      cleanName = cleanName.replace("X ", "");
    }

    /**
     * removes "Y " from the begining of the name
     */
    if (cleanName.indexOf("Y ") === 0) {
      cleanName = cleanName.replace("Y ", "");
    }

    /**
     * removes "Z " from the begining of the name
     */
    if (cleanName.indexOf("Z ") === 0) {
      cleanName = cleanName.replace("Z ", "");
    }

    /**
     * removes " Direccionadas al Personal"
     */
    cleanName = cleanName.replace(" Direccionadas al Personal", "");

    /**
     * removes "###TAG_NAME###" from the begining of the name and
     * creates a tag with "###TAG_NAME###" inside
     */
    const matchTag = cleanName.match(
      /^(CLUBBER|DANCE|DISCO|LATIN\sPOP|LATIN|POP|RELAX|ROCK|SINERGIA|TOP|URBAN|WORLD MUSIC|Fernando\sPicon|NORKY'S)\s+(.*)/
    );
    let tag = null;
    if (matchTag !== null) {
      // console.log("matchTag", matchTag[1], matchTag[2]);
      cleanName = matchTag[2];
      tag = <span className="box-category-tag">{matchTag[1]}</span>;
    } else {
      // console.log("not matchTag", cleanName);
    }

    /**
     * removes "- " from the begining of the name
     */
    if (cleanName.indexOf("- ") === 0) {
      cleanName = cleanName.replace("- ", "");
    }

    let icon = null;

    const matchTempo = cleanName.match(/(.*)\s+\((Up|Mid|Down|Mid \+ Up)\)/);
    if (matchTempo !== null) {
      // console.log("nameParsed", matchTempo);
      switch (matchTempo[2]) {
        case "Up":
          icon = (
            <img
              src={IconTempoUpSVG}
              alt="Up"
              title="Up Tempo"
              className="icon icon-tempo"
            />
          );
          break;
        case "Mid":
          icon = (
            <img
              src={IconTempoMidSVG}
              alt="Mid"
              title="Mid Tempo"
              className="icon icon-tempo"
            />
          );
          break;
        case "Mid + Up":
          <img
            src={IconTempoMidUpSVG}
            alt="Mid + Up"
            title="Mid and Up Tempo"
            className="icon icon-tempo"
          />;
          break;
        case "Down":
          icon = (
            <img
              src={IconTempoDownSVG}
              alt="Down"
              title="Down Tempo"
              className="icon icon-tempo"
            />
          );
          break;
        default:
          icon = null;
      }
    }
    cleanName = matchTempo !== null ? matchTempo[1] : cleanName;
    // console.log("cleanName", cleanName);
    if (text) {
      return cleanName;
    }
    return (
      <span className="box-name-wrapper">
        {tag ? tag : null}
        <span>{cleanName} </span>
        {icon}
      </span>
    );
  }
  return null;
}
