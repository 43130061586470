import React, { useState, useEffect, forwardRef } from "react";
import { useModal } from "../../hooks/useModal";
import Button from "../basic/Button";

const Modal = (
  { children, className, onClose, collapsed, disableClose },
  ref
) => {
  const { closeModal } = useModal(null);
  const [isLoaded, setIsLoaded] = useState(false);
  /**
   * adds a global style preventing the double scrolling when the modal scrolls
   */
  const bodyCSS = `body {overflow: hidden}`;

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 100);
  }, []);

  return (
    <>
      <div
        ref={ref}
        className={[
          "modal-wrapper",
          className !== undefined ? className : "",
          isLoaded ? "modal-loaded" : "modal-not-loaded",
        ].join(" ")}
      >
        {!collapsed && (
          <div
            className="modal-backdrop"
            onClick={() =>
              !disableClose ? (onClose ? onClose() : closeModal()) : null
            }
          />
        )}
        <div className="modal-container">
          <div className="modal-dialog">
            {!disableClose && (
              <div className="modal-header">
                <Button
                  onClick={() => (onClose ? onClose() : closeModal())}
                  className="close-modal-button"
                >
                  &times;
                </Button>
              </div>
            )}
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
      {!collapsed && <style>{bodyCSS}</style>}
    </>
  );
};

export default forwardRef(Modal);
