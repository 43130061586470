import axios from "axios";
import { useState, useContext } from "react";
import { createContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { API_URL } from "./useApi";

const TasksContext = createContext({
  tasks: [],
  submitTask: () => {},
  isLoading: false,
  isError: false,
  error: null,
  task: null,
  submitLoading: false,
  submitIsError: false,
  submitError: null,
  submitData: null,
  nameseo: null,
  setNameseo: () => {},
  currentTask: null,
  setCurrentTask: () => {},
});

export const TasksProvider = ({ children }) => {
  const [nameseo, setNameseo] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    error,
    data: taskData,
  } = useQuery(
    ["task", nameseo],
    async () => {
      const { data } = await axios.post(`${API_URL}getTask`, { nameseo });
      return data;
    },
    {
      enabled: !!nameseo,
    }
  );

  queryClient.setMutationDefaults("submitTask", {
    mutationFn: async (dataToSend) => {
      let url = `${API_URL}submitSonicboxTask`;
      const { data } = await axios.post(url, dataToSend);
      return data;
    },
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      queryClient.setQueryData("submitTask", (old) => result);
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on submitTask error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      queryClient.setQueryData("submitTask", (old) => null);
    },
    retry: 1,
  });

  const {
    mutateAsync,
    isLoading: submitLoading,
    isError: submitIsError,
    error: submitError,
    data: submitData,
  } = useMutation("submitTask");

  return (
    <TasksContext.Provider
      value={{
        submitTask: mutateAsync,
        isLoading: isLoading,
        isError: isError,
        error: error,
        tasks: taskData,
        submitLoading: submitLoading,
        submitIsError: submitIsError,
        submitError: submitError,
        submitData: submitData,
        nameseo: nameseo,
        setNameseo: setNameseo,
        currentTask,
        setCurrentTask,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = (nameseo) => {
  const taskContextData = useContext(TasksContext);
  taskContextData.setNameseo(nameseo);
  return taskContextData;
};
