import React, { useMemo } from "react";
import "../styles/now-playing.scss";
import DefaultSongImage from "../../../../images/default-song.jpg";
import DevPre from "../../../dev/DevPre";
import FavoriteButton from "../../../FavoriteButton";
import LoadableImage from "../../../basic/LoadableImage";
import { useDisplayRadio } from "../../../../hooks/useApi";
import Song from "./Song";
import cleanSongTitle from "../../../../utils/cleanSongTitle";

const NowPlaying = ({
  cover,
  playing,
  artist,
  playingsongid: songid,
  radioid,
}) => {
  const { isDisplay } = useDisplayRadio();

  const cleanedSongTitle = useMemo(
    () => cleanSongTitle(playing, artist),
    [playing, artist]
  );

  return (
    <div className="now-playing">
      <p>Ahora suena</p>
      {!isDisplay ? (
        <>
          <h4 title={playing}>{cleanedSongTitle}</h4>
          <p>{artist}</p>
          <DevPre>
            {typeof cover}
            <br />
            {JSON.stringify(cover, null, " ")}
          </DevPre>
          <div className="now-playing-cover-row">
            {!isDisplay ? <FavoriteButton songid={songid} /> : null}
            <LoadableImage
              src={cover !== "" ? cover : DefaultSongImage}
              alt={playing}
              className="song-cover"
            />
          </div>
        </>
      ) : (
        <Song
          songid={songid}
          songname={playing}
          artist={artist}
          cover={cover}
          radioid={radioid}
        />
      )}
    </div>
  );
};

export default NowPlaying;
