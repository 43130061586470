import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";

import { getNameseoByRadioid, useUser } from "../../../hooks/useApi";

import DevPre from "../../dev/DevPre";
import Loader from "../../basic/Loader";
import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "../../../styles/index.scss";
import decryptCode from "../../../utils/decryptCode";
import FacebookButton from "../../FacebookButton";
import GoogleButton from "../../GoogleButton";

const Redeem = ({ code }) => {
  const { user, logged, isKiosk, loginRedirect } = useUser();
  const [storedCodes, setStoredCodes] = useState([]);

  useEffect(() => {
    window.location.href = `https://millsonicbox.com/redeem/${code}`;
  }, [code]);

  useEffect(() => {
    const newCodes = JSON.parse(
      window.localStorage.getItem("sonicbox-codes") || "[]"
    );
    console.log(newCodes);
    if (newCodes.indexOf(code) > -1) {
      console.log("Code already saved");
    } else {
      newCodes.push(code);
      window.localStorage.setItem("sonicbox-codes", JSON.stringify(newCodes));
    }
    setStoredCodes(newCodes);
  }, [code]);

  useEffect(() => {
    console.log("useEffect logged", logged);
    if (logged) {
      setTimeout(async () => {
        if (storedCodes.length > 0) {
          console.log(storedCodes);
          const firstCode = decryptCode(storedCodes[0]);
          console.log("first code decrypted", firstCode);
          const splitted = firstCode.split("-");
          console.log(splitted);
          if (splitted.length > 1) {
            const codeRadioId = splitted[1];
            // navigate(`/sonicbox/${codeRadioId}`);
            const nameseo = await getNameseoByRadioid(codeRadioId);
            if (nameseo) {
              navigate(`/sonicbox/${nameseo}`);
            } else {
              navigate(`/sonicboxes`);
            }
          }
        }
      }, 2000);
    }
  }, [code, isKiosk, logged, storedCodes, loginRedirect]);

  return (
    <div className="container index-page">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      <p>
        {logged && user !== null ? user.name + ", bienvenido" : "Bienvenido"} a
        Sonicbox!
      </p>
      <DevPre>
        {typeof logged} - {logged ? "logged" : "not logged"}
      </DevPre>
      <h1>¡Genial, estamos verificando tu código!</h1>
      {logged ? (
        <Loader />
      ) : (
        <>
          <p>Debes ingresar para que poder utilizar tus créditos.</p>
          <FacebookButton />
          <GoogleButton />
          <p>
            <Link to="/login">Ingresa</Link> con tu usuario y contraseña
          </p>
        </>
      )}
    </div>
  );
};

export default Redeem;
