import React from "react";
import Button from "../../../basic/Button";
import "../styles/single-local.scss";

const SingleLocal = ({
  name,
  nameseo,
  radioid,
  radioimage,
  onClick,
  label,
}) => {
  return (
    <li className="single-local">
      <header>
        <img src={radioimage} alt={`logo ${name}`} className="local-logo" />
        <h3>{name}</h3>
      </header>
      <Button onClick={() => onClick(nameseo, radioid)} className="success">
        {label}
      </Button>
    </li>
  );
};

export default SingleLocal;
