import TaskForm from "./components/TaskForm";
import Memory from "./components/Memory";
import SlidePuzzle from "./components/SlidePuzzle";
import Merge from "./components/Merge";

export default function TaskSwitcher({
  type,
  task,
  values,
  setValues,
  setIsValid,
  onSuccess,
}) {
  const { data: taskData, title } = task || {data: [], title: ""};
  switch (type) {
    case "survey":
      return (
        <TaskForm
          title={title}
          fields={taskData}
          values={values}
          setValues={setValues}
          setIsValid={setIsValid}
          onSuccess={onSuccess}
        />
      );
    case "memory":
      return (
        <Memory
          title={title}
          taskData={taskData}
          values={values}
          setValues={setValues}
          setIsValid={setIsValid}
          onSuccess={onSuccess}
        />
      );
    case "slide":
      return (
        <SlidePuzzle
          title={title}
          taskData={taskData}
          values={values}
          setValues={setValues}
          setIsValid={setIsValid}
          onSuccess={onSuccess}
        />
      );
    case "merge":
      return (
        <Merge
          title={title}
          taskData={taskData}
          values={values}
          setValues={setValues}
          setIsValid={setIsValid}
          onSuccess={onSuccess}
        />
      );
    default:
      return <div>Task type not found</div>;
  }
}
