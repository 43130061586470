import React, { useState, useEffect } from "react";
import { useCards } from "../../hooks/useApi";
import Button from "../basic/Button";
import CreditCardForm from "../CreditCardForm";
import DevPre from "../dev/DevPre";
import Loader from "../basic/Loader";
import Modal from "../layout/Modal";
import "../../styles/modal-credit-card.scss";
import CreditCard from "../CreditCard";

const CreditCardsModal = () => {
  const { cards, isLoading, isError, error, updateCards } = useCards();
  const [newCard, setNewCard] = useState(false);

  useEffect(() => {
    if (!newCard && Array.isArray(cards) && cards.length === 0) {
      setNewCard(true);
    }
  }, [newCard, cards, isLoading, isError]);

  const handleNewCard = (values) => {
    updateCards();
    setNewCard(false);
  };

  return (
    <Modal className="modal-credit-card">
      {newCard ? (
        <div className="new-card">
          <h3>Agregar tarjeta</h3>
          <CreditCardForm onSuccess={handleNewCard} />
        </div>
      ) : (
        <div className="credit-cards">
          <h3>Tarjetas asociadas</h3>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <DevPre>{JSON.stringify(error, null, " ")}</DevPre>
          ) : cards.length === 0 ? (
            <>
              <p>No tienes tarjetas asociadas aún.</p>
            </>
          ) : (
            <>
              <div className="cards-list">
                {cards.map((card, index) => (
                  <CreditCard
                    {...card}
                    key={card.ucid}
                    onClick={() => alert("click card")}
                    enableDelete
                  />
                ))}
              </div>
            </>
          )}
          <Button className="bordered accent add-card-button">
            Agrega una tarjeta
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default CreditCardsModal;
