import React, { useMemo, useRef } from "react";
import "../styles/box.scss";
import MenuToggleSVG from "../../../../inline-svg/menu-toggle.svg";
import { scrollIntoView } from "../../../../utils/domManipulation";
import cleanBoxName from "../../../../helpers/cleanBoxName";

const Box = ({
  isHeading,
  isSelected,
  isHidden,
  onClick,
  style,
  cover,
  name,
  playlists,
  covers,
  targetRef,
  targetOffset,
}) => {
  const boxRef = useRef();
  const handleOnClick = (event) => {
    if (targetRef && targetRef.current !== undefined) {
      scrollIntoView(targetRef.current, targetOffset);
    }
    if (typeof onClick === "function") {
      onClick(event);
    }
  };
  const parsedName = useMemo(() => {
    return cleanBoxName(name);
  }, [name]);

  return (
    <button
      ref={boxRef}
      type="button"
      className={[
        "box",
        isHeading ? "heading" : "not-heading",
        isSelected ? "selected" : "not-selected",
        isHidden ? "hidden" : "visible",
      ].join(" ")}
      onClick={handleOnClick}
      onKeyPress={(event) => {
        console.log(event.key);
      }}
      style={style ? style : null}
    >
      <div className="box-content">
        {isHeading ? <img src={MenuToggleSVG} alt="Menu" /> : null}
        <CoverGrid covers={covers ? covers : []} />
        <div className="box-info">
          <h3>{parsedName}</h3>
        </div>
      </div>
    </button>
  );
};

export default Box;

export const Playlist = (props) => {
  const handleOnSelectBox = () => {
    props.onSelectBox(parseInt(props.playlistid));
  };
  return (
    <Box
      {...props}
      covers={[props.playlistcover]}
      name={props.playlistname}
      onClick={handleOnSelectBox}
    />
  );
};

export const BoxParent = (props) => {
  const covers = useMemo(() => {
    if (props.cover && props.cover !== "") {
      return [props.cover];
    }
    return props.playlistsObjects.map((playlist) => playlist.playlistcover);
  }, [props.cover, props.playlistsObjects]);
  return <Box {...props} covers={covers} />;
};

export const CoverGrid = ({ covers }) => {
  const sqrt = Math.ceil(Math.sqrt(covers.length));
  return (
    <div className={["cover-grid", `grid-${sqrt}`].join(" ")}>
      {covers.map((cover, index) => {
        const httpsCover = cover.replace("http://", "https://");
        return <img key={index.toString()} src={httpsCover} alt="cover" />;
      })}
    </div>
  );
};
