import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { useUser } from "../hooks/useApi";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { logged, initiated } = useUser();

  useEffect(() => {
    console.log("PrivateRoute", location.pathname);
    (async () => {
      if (!logged && location.pathname !== `/app` && initiated) {
        console.log("navigate to home");
        await navigate("/");
      }
    })();
  }, [logged, location, rest, initiated]);

  if (logged) {
    return <Component {...rest} />;
  }
  return null;
};

export default PrivateRoute;
