import React from "react";
import PropTypes from "prop-types";

import NavBar from "./NavBar";
import { ScrollProvider } from "../../hooks/useScrollObserver";
import { ApiProvider } from "../../hooks/useApi";

import { ModalsProvider } from "../../hooks/useModal";
import modals from "../modals";
import { AdsProvider } from "../../hooks/useAds";

const Layout = ({ children }) => {
  return (
    <ApiProvider>
      <AdsProvider>
        <ModalsProvider modals={modals}>
          <ScrollProvider className="layout">
            <NavBar siteTitle={`Sonicbox`} />
            <main>{children}</main>
            <footer
              className="container"
              style={{
                marginTop: `2rem`,
              }}
            >
              {`© ${new Date().getFullYear()}, Built by `}
              <a href="https://millsonic.com">Millsonic</a>
              <span>{` v${process.env.REACT_APP_VERSION}`}</span>
            </footer>
          </ScrollProvider>
        </ModalsProvider>
      </AdsProvider>
    </ApiProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
