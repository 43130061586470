import React from "react";

const Orders = () => {
  return (
    <div className="orders">
      <h1>Mis compras</h1>
    </div>
  );
};

export default Orders;
