import React, { useState } from "react";
import { useUser } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";
import DevPre from "../../dev/DevPre";
import "./styles/style.scss";
import InputText from "../../basic/InputText";
import Button from "../../basic/Button";

const Login = function () {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { login, mutation } = useUser();
  const { isLoading, isError, error } = mutation;

  const handleUpdate = (value, name) => {
    switch (name) {
      case "username":
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // handleLogin({ username: username, password: password })
    login(
      { email: username, pass: password, type: "normal" },
      {
        onError: (error) => console.log("onError", error),
        onSettled: (settled) => console.log("onSettled", settled),
        onSuccess: (success) => console.log("onSuccess", success),
      }
    );
  };

  // useEffect(() => {
  //   if (logged) {
  //     navigate(loginRedirect);
  //   }
  //   return () => {
  //     console.log("navigate to profile");
  //   };
  // }, [logged, loginRedirect]);

  return (
    <div className="container login">
      <h1>Log in</h1>
      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <InputText
          label="E-mail"
          type="text"
          name="username"
          onChange={handleUpdate}
        />
        <InputText
          label="Password"
          type="password"
          name="password"
          onChange={handleUpdate}
        />
        <Button type="submit" block className="success">
          Ingresar
        </Button>
      </form>
      {isError ? (
        <DevPre style={{ color: "red" }}>
          {JSON.stringify(error, null, " ")}
        </DevPre>
      ) : isLoading ? (
        <Loader />
      ) : null}
    </div>
  );
};
export default Login;
