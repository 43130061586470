import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import { useSongs } from "../../../../hooks/useApi";
import Song from "./Song";
import "../styles/songs.scss";
import Loader from "../../../basic/Loader";
import Button from "../../../basic/Button";
import DevPre from "../../../dev/DevPre";
import InView from "react-intersection-observer";
import cleanBoxName from "../../../../helpers/cleanBoxName";

const Songs = (
  { playlistid, playlistname, search, setSearch, radioid },
  ref
) => {
  const lastLoadMoreOffset = useRef(0);
  const lastPlaylistId = useRef(playlistid);
  const lastSearch = useRef(search);

  const {
    songs,
    total,
    isLoading,
    isError,
    error,
    loadMore,
    isFetchingNextPage,
  } = useSongs(playlistid, search, radioid);

  useEffect(() => {
    if (playlistid !== lastPlaylistId.current) {
      lastPlaylistId.current = playlistid;
      lastLoadMoreOffset.current = 0;
    }
  }, [playlistid, lastPlaylistId]);

  useEffect(() => {
    if (search !== lastSearch.current) {
      lastSearch.current = search;
      lastLoadMoreOffset.current = 0;
    }
  }, [search, lastSearch]);

  const handleShowMore = () => {
    loadMore();
  };

  const songsLength = useMemo(() => {
    let countSongs = 0;
    if (songs) {
      if (songs?.pages && songs.pages) {
        songs.pages.forEach((page) => {
          countSongs += page.data.length;
        });
      }
    }
    return countSongs;
  }, [songs]);

  const handleReachListEnd = (count) => {
    console.log(
      "isFetchingNextPage",
      isFetchingNextPage,
      lastLoadMoreOffset.current,
      count
    );
    if (!isFetchingNextPage && lastLoadMoreOffset.current < count) {
      lastLoadMoreOffset.current = count;
      loadMore();
    }
  };

  const parsedPlaylistName = useMemo(() => {
    return cleanBoxName(playlistname, true);
  }, [playlistname]);

  let message = null;

  const songsCountMsg =
    total === undefined
      ? `buscando canciones`
      : total - songsLength === 1
      ? `${total - songsLength} canción`
      : `${total - songsLength} canciones`;

  if (search !== "") {
    message = `${songsCountMsg} para "${search}"`;
  } else if (playlistid) {
    message = `${songsCountMsg} en "${parsedPlaylistName}"`;
  }

  return (
    <InView as="div" initialInView={false}>
      {({ inView, ref, entry }) => {
        if (inView && songs !== undefined && songs.pages) {
          handleReachListEnd(
            songs.pages.reduce((prev, page) => prev + page.data.length, 0)
          );
        }
        return (
          <div className="songs" ref={ref}>
            {message ? <p>{message}</p> : null}
            {isLoading ? (
              <Loader />
            ) : isError ? (
              <p className="error">{error.message}</p>
            ) : songs ? (
              <>
                {songs.pages.map((page, pageIndex) =>
                  page?.data
                    ? page.data.map((song, index) => (
                        <Song key={song.songid} {...song} />
                      ))
                    : null
                )}
                <div ref={ref} className="songs-scroll-trigger" />
              </>
            ) : (
              <DevPre>
                {typeof songs}
                <hr />
                {JSON.stringify(songs, null, " ")}
              </DevPre>
            )}
            {total !== undefined ? (
              <div>
                {total > songsLength ? (
                  isFetchingNextPage ? (
                    <Loader />
                  ) : (
                    <Button
                      onClick={handleShowMore}
                      disabled={isFetchingNextPage}
                      block
                      className="view-more-button blue"
                    >
                      Ver más
                    </Button>
                  )
                ) : (
                  <p>No hay más resultados</p>
                )}
                <p>{`${songsLength} de ${total}`}</p>
              </div>
            ) : null}
          </div>
        );
      }}
    </InView>
  );
};

export default forwardRef(Songs);
