import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { IDLE_WAITING_TIME } from "../constants";
import { useUser } from "./useApi";
import useInterval from "./useInterval";
import "../styles/ads.scss";

const AdsContext = createContext(null);

export const AdsProvider = ({ children }) => {
  const { user } = useUser();

  //showFullAd: boolean tells wether show ads or hide them
  const [showFullAd, setShowFullAd] = useState(true);
  //showBottomAd: boolean tells wether show ads or hide them
  // eslint-disable-next-line
  const [showBottomAd, setShowBottomAd] = useState(true);
  //showKeyboard: string indicating if the keyboard is active (!= "")
  //  and the current target of the keyboard ["search"]
  const [showKeyboard, setShowKeyboard] = useState("");
  //waitAd: ref to the last time the user interacted with the app
  const waitAd = useRef();

  const [currentAd, setCurrentAd] = useState(
    user && user.hasOwnProperty("ads") && user.ads.length > 0
      ? user.ads[0]
      : null
  );
  const [positionFull, setPositionFull] = useState("to-left");
  const [positionBottom, setPositionBottom] = useState("to-bottom");
  const time = useRef();
  const fullVideoRef = useRef();
  const bottomVideoRef = useRef();

  const logLastClick = useCallback(() => {
    waitAd.current = new Date().getTime();
    setShowFullAd(false);
    setTimeout(() => {
      const passedTime =
        new Date().getTime() - waitAd.current >= IDLE_WAITING_TIME;
      setShowFullAd(passedTime);
      if (passedTime) {
        setTimeout(() => {
          if (new Date().getTime() - waitAd.current >= IDLE_WAITING_TIME) {
            // setScreen(SCREEN_HOME);
            // setSearchParams({ ...searchParams, playlistid: null, offset: 0 });
            // setPlaylistId(null);
          }
        }, 2000);
      }
    }, IDLE_WAITING_TIME);
  }, [
    waitAd,
    setShowFullAd,
    // setScreen,
    // setSearchParams,
    // setPlaylistId,
    // searchParams,
  ]);

  const fullTime = useMemo(() => {
    if (user && user.hasOwnProperty("ads") && user.hasOwnProperty("adswait")) {
      const ft =
        user.ads.reduce((value, current) => {
          return value + current.time + user.adswait;
        }, 0) + user.adswait;
      return ft;
    }
    return 0;
  }, [user]);

  const ticker = useCallback(() => {
    if (user && user.hasOwnProperty("ads") && user.ads.length > 0) {
      if (time.current === undefined || !showFullAd) {
        time.current = 0;
      } else {
        time.current += 100;
      }
      let addedTime = 0;
      user.ads.forEach((singleAd, i) => {
        const currTime = time.current % fullTime;
        const endTime = addedTime + singleAd.time + user.adswait;
        const isCurrent = addedTime < currTime && currTime < endTime;
        // console.log("ticker", "fullTime", fullTime, "currTime", currTime);
        // console.log(currentAd, singleAd);
        if (isCurrent) {
          if (currentAd === null || currentAd.url !== singleAd.url) {
            setCurrentAd(singleAd);
            // console.log("current ad", singleAd);
          }
          switch (currTime) {
            case addedTime:
              setPositionFull("to-left");
              setPositionBottom("to-bottom");
              // console.log("currTime === addedTime");
              break;
            case addedTime + 100:
              // console.log("currTime === addedTime + 100");
              switch (singleAd.position) {
                case "full":
                  setPositionFull("center");
                  if (fullVideoRef.current !== undefined) {
                    fullVideoRef.current.play();
                  }
                  break;
                case "bottom":
                  setPositionBottom("to-top");
                  if (bottomVideoRef.current !== undefined) {
                    bottomVideoRef.current.play();
                  }
                  break;
                default:
                  break;
              }
              break;
            case addedTime + singleAd.time:
              // console.log("currTime === addedTime + singleAd.time");
              setPositionFull("to-right");
              setPositionBottom("to-bottom");
              break;
            default:
              break;
          }
        }
        addedTime = endTime;
      });
    }
  }, [user, time, currentAd, showFullAd, fullTime, fullVideoRef]);

  useInterval(() => ticker(), 100, [user, time, currentAd, showFullAd]);

  const handleGeneralClick = (event) => {
    logLastClick();
    if (showKeyboard !== "") {
      //hides keyboard when clicking on App div
      setShowKeyboard("");
    }
  };

  return (
    <AdsContext.Provider
      value={{
        showFullAd: showFullAd,
        showBottomAd: showBottomAd,
        wait: user ? user?.adswait : 0,
        onClick: handleGeneralClick,
      }}
    >
      <div
        className="ads-wrapper"
        onClick={handleGeneralClick}
        onTouchStart={(e) => {
        }}
      >
        {children}
        {currentAd ? (
          <>
            <div
              className={[
                "ad-container",
                "ad-bottom-container",
                showFullAd && currentAd.position === "bottom" ? "show" : "hide",
              ].join(" ")}
            >
              {showBottomAd ? (
                <div
                  onClick={handleGeneralClick}
                  style={{
                    backgroundImage: `url(${currentAd.poster})`,
                  }}
                  className={[
                    "ad",
                    "ad-bottom",
                    positionBottom !== null ? positionBottom : "",
                  ].join(" ")}
                >
                  {currentAd.type === "video" ? (
                    <video
                      ref={bottomVideoRef}
                      src={currentAd.url}
                      style={{ width: "100%", height: "100%" }}
                      autoPlay
                      muted
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              className={[
                "ad-container",
                showFullAd && currentAd.position === "full" ? "show" : "hide",
              ].join(" ")}
            >
              {showFullAd ? (
                <div
                  onClick={handleGeneralClick}
                  style={{
                    backgroundImage: `url(${currentAd.poster})`,
                  }}
                  className={[
                    "ad",
                    "ad-full",
                    positionFull !== null ? positionFull : "",
                  ].join(" ")}
                >
                  {currentAd.type === "video" ? (
                    <video
                      ref={fullVideoRef}
                      src={currentAd.url}
                      style={{ width: "100%", height: "100%" }}
                      autoPlay
                      muted
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </AdsContext.Provider>
  );
};

/**
 * @typedef UseAdsObject
 * @property {boolean} props.showFullAd - wether full ad must be shown or not
 * @property {boolean} props.showBottomAd - wether bottom ad must be shown or not
 * @property {function} props.onClick - callback to be called when user clicks on ad
 * @property {number} props.wait - the period to wait after each ad
 */
