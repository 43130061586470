import axios from "axios";

const versionUpdater = () => {
  const APP_VERSION = process.env.REACT_APP_VERSION;
  console.log(`Version: ${APP_VERSION}`);
  let asking = false;

  setInterval(() => {
    axios
      .get("/version.json", {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((r) => {
        console.log(r.data.version);
        console.log(r.data.version > APP_VERSION, r.data.version, APP_VERSION);
        if (
          !asking &&
          r?.data?.version &&
          r.data.version !== APP_VERSION &&
          r.data.version > APP_VERSION
        ) {
          asking = true;
          window.location.reload();
          asking = false;
        }
      });
  }, 60 * 1000);
};

export default versionUpdater;
