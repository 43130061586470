import { useEffect, useState } from "react";
import { useTerms } from "../../hooks/useApi";
import Loader from "../basic/Loader";
import Modal from "../layout/Modal";
import Button from "../basic/Button";

import "../../styles/terms.scss";

export default function TermsAndConditionsModal({
  nameseo,
  isPeeking,
  setIsPeeking,
}) {
  const { refetch, data, isLoading, acceptTerms } = useTerms(nameseo);
  const [ticked, setTicked] = useState(false);

  const handleAcceptTerms = () => {
    console.log("accepting terms");
    acceptTerms.mutate();
  };

  const handleClosePeeking = () => {
    setIsPeeking(false);
  };

  useEffect(() => {
    if (acceptTerms.isSuccess) {
      refetch();
    }
  }, [acceptTerms.isSuccess, refetch]);

  if (isLoading) return <Loader />;
  if (data) {
    return (
      <Modal disableClose={true} className="terms-modal">
        <div
          dangerouslySetInnerHTML={{ __html: data.text }}
          className="terms-container"
          style={{
            height: Math.max(20, Math.min(70, data.text.length * 0.08)) + "vh",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isPeeking ? (
            <Button
              onClick={handleClosePeeking}
              className="success"
              style={{ marginLeft: 10 }}
            >
              Cerrar
            </Button>
          ) : (
            <>
              <input
                type="checkbox"
                checked={ticked}
                onChange={(event) => setTicked((ticked) => !ticked)}
                style={{ width: 30, height: 30 }}
              />
              <Button
                onClick={handleAcceptTerms}
                className="success"
                disabled={!ticked}
                style={{ marginLeft: 10 }}
              >
                {acceptTerms?.isLoading ? <Loader /> : "Autorizar"}
              </Button>
            </>
          )}
        </div>
      </Modal>
    );
  }
  return null;
}
