import React, { useState } from "react";
import Modal from "../layout/Modal";
import PointsInputWidget from "../PointsInputWidget";
import "../../styles/modal-buy.scss";
import Button from "../basic/Button";
import GreyArrowBackSVG from "../../images/grey-arrow-back.svg";
import { useModal } from "../../hooks/useModal";
import { useUser } from "../../hooks/useApi";
import DevPre from "../dev/DevPre";
import CreditCards from "../CreditCards";

const BuyModal = () => {
  const [creditsToBuy, setCreditsToBuy] = useState(10);
  const [step, setStep] = useState(0);
  const { user } = useUser();
  const { points } = user;
  const { closeModal } = useModal();
  const price = 20 * creditsToBuy;

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      closeModal();
    }
  };

  return (
    <Modal className="modal-buy">
      <div className="credits-message">
        tienes <strong>{`${points} crédito${points === 1 ? `` : `s`}`}</strong>
      </div>
      <DevPre>{step}</DevPre>
      <h2>
        <span>Compra créditos</span>
        <small>y úsalos en la Sonicbox que desees</small>
      </h2>
      <div className="steps-container">
        {step === 0 ? (
          <PointsInputWidget
            value={creditsToBuy}
            onChange={setCreditsToBuy}
          />
        ) : null}
        {step === 1 ? (
          <div className="payments-container">
            <CreditCards />
          </div>
        ) : null}
      </div>
      <div className="checkout-summary">
        <h3>Resumen</h3>
        <table>
          <tbody>
            <tr className="entry-row">
              <td>{`${creditsToBuy} crédito${
                creditsToBuy === 1 ? `` : `s`
              }`}</td>
              <td className="number">{`$${price}`}</td>
            </tr>
            <tr className="total-row">
              <td>
                <strong>Total</strong>
              </td>
              <td className="number">{`$${price}`}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-container">
        <Button className="bordered" size="big" onClick={handlePrevStep}>
          <img
            src={GreyArrowBackSVG}
            alt="flecha hacia atrás"
            className="icon"
          />
        </Button>
        <Button className="success" size="big" onClick={handleNextStep}>
          Continuar
        </Button>
      </div>
    </Modal>
  );
};

export default BuyModal;
