import React, { useEffect } from "react";
import { useDisplay, useDisplayRadio } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";

import "./styles/display.scss";

import NowPlaying from "../sonicbox/components/NowPlaying";
import NextPlaying from "../sonicbox/components/NextPlaying";

import GetAppQR from "../../../images/getapp-qr.png";
import AppStoreButton from "./components/AppStoreButton";
import { PLATFORM_ANDROID, PLATFORM_APPLE } from "../../../constants";
import DevPre from "../../dev/DevPre";

const Display = () => {
  const { isLoading, localData, isError, error, data } = useDisplay();

  const { radioid } = useDisplayRadio();

  useEffect(() => {
    console.log("Display useEffect change radioid", radioid);
  }, [radioid]);

  return (
    <div className="display container">
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <p className="error">{error}</p>
      ) : (
        <div className={["sonicbox", "sonicbox-display"].join(" ")}>
          <div className="col-50">
            <h2>
              {`Elegí cómo sigue la música`}
              <br />
              {`Descargá Sonicbox en tu celular`}
            </h2>
            <img
              src={GetAppQR}
              alt={"https://millsonic.com/getApp/sonicbox"}
              className="get-app-qr"
            />
            <div className="app-store-btns">
              <AppStoreButton platform={PLATFORM_APPLE} />
              <AppStoreButton platform={PLATFORM_ANDROID} />
            </div>
            <DevPre>{JSON.stringify(data, null, " ")}</DevPre>
          </div>
          <div className="col-50">
            <NowPlaying
              cover={data?.cover}
              playing={data?.playing}
              artist={data?.artist}
              songid={data?.playingsongid}
              radioid={radioid}
            />
            <NextPlaying {...localData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Display;
