import { Link, useMatch } from "@reach/router";
import React from "react";
import {
  PROFILE_SECTION_FAVORITES,
  PROFILE_SECTION_ORDERS,
  PROFILE_SECTION_PROFILE,
} from "../../../constants";
import "./styles/account-navbar.scss";

const AccountNavBar = () => {
  const match = useMatch("/account/:section");
  const section = match?.section || "";

  return (
    <nav className="navbar account-navbar">
      <Link
        to={`/account/${PROFILE_SECTION_ORDERS}`}
        className={section === PROFILE_SECTION_ORDERS ? "active" : ""}
      >
        Mis compras
      </Link>
      <Link
        to={`/account/${PROFILE_SECTION_FAVORITES}`}
        className={section === PROFILE_SECTION_FAVORITES ? "active" : ""}
      >
        Mis canciones
      </Link>
      <Link
        to={`/account/${PROFILE_SECTION_PROFILE}`}
        className={section === PROFILE_SECTION_PROFILE ? "active" : ""}
      >
        Mis datos
      </Link>
    </nav>
  );
};

export default AccountNavBar;
