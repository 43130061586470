import { useEffect, useRef } from "react";
import { useKioskLogin } from "../../../hooks/useApi";
import { KIOSK_CODE_LENGTH } from "../../../constants";
import Loader from "../../basic/Loader";

export default function KioskWithCode({ code }) {
  const { mutation, sendCode } = useKioskLogin();

  const sentCode = useRef();

  useEffect(() => {
    console.log("code", code, "current", sentCode.current);
    console.log("code", typeof code, "current", typeof sentCode.current);
    if (code.length === KIOSK_CODE_LENGTH && sentCode.current !== code) {
      sentCode.current = code;
      console.log("sendCode", { code: code });
      mutation.reset();
      sendCode(
        {
          code: code,
        },
        () => {
          console.log("trigger Callback");
        }
      );
    }
  }, [code, sentCode, sendCode, mutation]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ width: "100%", textAlign: "center", marginBottom: 30 }}>
        ¡Bienvenido a Sonicbox!
      </h1>
      <Loader />
    </div>
  );
}
