import React from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-vote.scss";
import Button from "../basic/Button";
import { useLocal, useUser } from "../../hooks/useApi";
import { useModal } from "../../hooks/useModal";
import { BUY_MODAL, CODE_MODAL } from "../../constants";

const AddCreditsModal = () => {
  const { stackModal: openCodesModal } = useModal(CODE_MODAL);
  const { stackModal: openBuyModal } = useModal(BUY_MODAL);
  const { user, isKiosk } = useUser();
  const { data: localData } = useLocal();

  return (
    <Modal className="modal-add-credits modal-vote">
      <p className="credits-message">
        <strong>{`${user.points === 0 ? "No tienes" : user.points} credito${
          user.points === 1 ? "" : "s"
        }`}</strong>{" "}
        {`disponible${user.points === 1 ? "" : "s"}`}
        {!isKiosk && localData ? (
          <>
            <br />
            <span>
              {localData.fcreditsuser > 0
                ? `Tienes ${localData.fcreditsuser} crédito${
                    localData.fcreditsuser === 1 ? "" : "s"
                  } gratis en ${localData.name}`
                : `No tienes créditos gratis en ${localData.name}`}
            </span>
          </>
        ) : null}
      </p>
      {!isKiosk ? (
        <Button size="big" className="bordered brand" onClick={openBuyModal}>
          Comprar créditos
        </Button>
      ) : null}
      <Button size="big" className="bordered brand" onClick={openCodesModal}>
        Ingresar código
      </Button>
    </Modal>
  );
};

export default AddCreditsModal;
