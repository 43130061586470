import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "./styles/sonicboxes.scss";
import Locales from "./components/Locales";

export default function Sonicboxes() {
  useEffect(() => {
    window.location.href = `https://millsonicbox.com/sonicboxes`;
  }, []);

  return (
    <div className="container sonicboxes">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      <Locales
        onEnter={(nameseo) => navigate(`/sonicbox/${nameseo}`)}
        buttonLabel="Entrar"
      />
    </div>
  );
}
