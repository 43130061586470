import React, { createContext, useContext } from "react";
import InView from "react-intersection-observer";

const ScrollContext = createContext();

export const useScrollObserver = () => {
  const { inView, ref, entry } = useContext(ScrollContext);
  return { inView: inView, ref: ref, entry: entry };
};

export const ScrollProvider = (props) => {
  return (
    <div className={props.className}>
      <InView {...props} as="div" initialInView={true}>
        {({ inView, ref, entry }) => (
          <ScrollContext.Provider
            value={{ inView: inView, ref: ref, entry: entry }}
          >
            {props.children}
          </ScrollContext.Provider>
        )}
      </InView>
    </div>
  );
};
