import { useCallback, useMemo, useEffect, useState, useRef } from "react";
import Button from "../../../basic/Button";
import ProgressBar from "./ProgressBar";

export default function SlidePuzzle({
  taskData,
  values,
  setValues,
  setIsValid,
  onSuccess,
}) {
  const [tries, setTries] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const { image, totalTiles, width, height } = taskData;
  const tilesX = Math.floor(Math.sqrt(totalTiles));
  const tilesY = Math.floor(Math.sqrt(totalTiles));
  const containerRef = useRef();
  const [tiles, setTiles] = useState([]);
  const [emptyTile, setEmptyTile] = useState(null);
  const [isMoving, setIsMoving] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(1);

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      const { width: containerWidth } =
        containerRef.current.parentNode.getBoundingClientRect();
      setScaleFactor((containerWidth - 64) / width);
      console.log("scaleFactor", scaleFactor, containerWidth, width);
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize();
    }
  }, [handleResize]);

  const [adaptiveWidth, adaptiveHeight] = useMemo(() => {
    return [width * scaleFactor, height * scaleFactor];
  }, [width, height, scaleFactor]);

  useEffect(() => {
    if (Array.isArray(tiles) && tiles.length === 0) {
      const newTiles = [];
      const tileWidth = width / tilesX;
      const tileHeight = height / tilesY;
      for (let i = 0; i < tilesY; i++) {
        for (let j = 0; j < tilesX; j++) {
          const tile = {
            x: j,
            y: i,
            imageX: j,
            imageY: i,
            width: tileWidth,
            height: tileHeight,
            image: image,
          };
          newTiles.push(tile);
        }
      }
      const _emptyTile = newTiles[newTiles.length - 1];
      setEmptyTile(_emptyTile);
      setTiles(scrambleTiles(newTiles, _emptyTile));
    }
  }, [tiles, width, height, tilesX, tilesY, image]);

  useEffect(() => {
    if (!startTime) {
      setStartTime(Date.now());
    }
  }, [isMoving, startTime]);

  const isSolved = useMemo(
    () =>
      tiles.length > 0 &&
      tiles.every((tile) => {
        return tile.x === tile.imageX && tile.y === tile.imageY;
      }),
    [tiles]
  );

  const { misplaced, percentSolved } = useMemo(() => {
    const misplaced = countMisplaced(tiles);
    const percentSolved = Math.round((1 - misplaced / tiles.length) * 100);
    return { misplaced, percentSolved };
  }, [tiles]);

  const moveTile = (tile) => {
    if (isMoving || isSolved) {
      return;
    }
    const { x, y } = tile;
    const { x: emptyX, y: emptyY } = emptyTile;
    if (
      (x === emptyX && Math.abs(y - emptyY) === 1) ||
      (y === emptyY && Math.abs(x - emptyX) === 1)
    ) {
      setIsMoving(true);
      setTries(tries + 1);
      if (tries === 0) {
        setStartTime(new Date().getTime());
      }
      const newTiles = tiles.map((tile) => {
        if (tile.x === x && tile.y === y) {
          return {
            ...tile,
            x: emptyX,
            y: emptyY,
          };
        } else if (tile.x === emptyX && tile.y === emptyY) {
          return {
            ...tile,
            y: y,
            x: x,
          };
        } else {
          return tile;
        }
      });
      setTiles(newTiles);
      setEmptyTile({ ...emptyTile, x: x, y: y });
      setTimeout(() => {
        setIsMoving(false);
      }, 100);
    }
  };

  useEffect(() => {
    if (isSolved && !values?.time) {
      const endTime = new Date().getTime();
      const time = Math.round((endTime - startTime) / 1000);
      const newValues = { ...values, time, tries };
      console.log(endTime, startTime);
      console.log("solved", newValues);
      setValues(newValues);
      setIsValid(true);
    }
  }, [isSolved, startTime, tries, values, setValues, setIsValid]);

  return (
    <>
      <div
        ref={containerRef}
        style={{
          position: "relative",
          width: adaptiveWidth,
          height: adaptiveHeight,
          margin: "4rem auto 0",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          overflow: "hidden",
        }}
      >
        {tiles.map((tile) => (
          <Tile
            key={`${tile.x}-${tile.y}`}
            tile={tile}
            onClick={moveTile}
            isEmpty={tile.x === emptyTile.x && tile.y === emptyTile.y}
            containerWidth={adaptiveWidth}
            containerHeight={adaptiveHeight}
            isSolved={isSolved}
            scaleFactor={scaleFactor}
          />
        ))}
      </div>
      <div
        style={{
          margin: "0 auto",
          width: adaptiveWidth,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
          overflow: "hidden",
        }}
      >
        <ProgressBar progress={percentSolved} color="#00ffee" />
      </div>
      <div
        className={["memory-solved", isSolved ? "visible" : "not-visible"].join(
          " "
        )}
      >
        <h2>¡Felicitaciones!</h2>
        <p>{`Has completado el puzzle en ${tries} movimiento${
          tries !== 1 && "s"
        } y ${values?.time} segundos`}</p>
        <Button
          onClick={() => {
            setTiles([]);
          }}
          className={"success"}
        >
          Reiniciar
        </Button>
        <Button onClick={() => onSuccess()} className={"success"}>
          Continuar
        </Button>
      </div>
    </>
  );
}

function Tile({
  tile,
  onClick,
  isEmpty,
  containerWidth,
  containerHeight,
  isSolved,
  scaleFactor,
}) {
  const { x, y, width, height, image, imageX, imageY } = tile;
  const style = {
    position: "absolute",
    left: x * width * scaleFactor,
    top: y * height * scaleFactor,
    width: width * scaleFactor,
    height: height * scaleFactor,
    backgroundImage: (!isEmpty || isSolved) && `url(${image})`,
    backgroundPosition: `-${imageX * width * scaleFactor}px -${
      imageY * height * scaleFactor
    }px`,
    backgroundSize: `${containerWidth}px ${containerHeight}px`,
    boxShadow: !isEmpty && !isSolved && "0 0 8px #000",
    cursor: "pointer",
    zIndex: 98 - x * 3 - y,
  };
  return <div className="tile" style={style} onClick={() => onClick(tile)} />;
}

function scrambleTiles(_tiles, emptyTile) {
  let misplaced = countMisplaced(_tiles);
  while (misplaced < _tiles.length / 2) {
    const tile = _tiles[Math.floor(Math.random() * _tiles.length)];

    const { x, y } = tile;
    const { x: emptyX, y: emptyY } = emptyTile;
    if (
      (x === emptyX && Math.abs(y - emptyY) === 1) ||
      (y === emptyY && Math.abs(x - emptyX) === 1)
    ) {
      swapTiles(tile, emptyTile);
    }
    misplaced = countMisplaced(_tiles);
  }
  return _tiles;
}

function swapTiles(tileA, tileB) {
  const { x, y } = tileA;
  tileA.x = tileB.x;
  tileA.y = tileB.y;
  tileB.x = x;
  tileB.y = y;
}

function countMisplaced(tiles) {
  let misplaced = tiles.reduce((prev, curr) => {
    if (curr.x !== curr.imageX || curr.y !== curr.imageY) {
      return prev + 1;
    }
    return prev;
  }, 0);
  return misplaced;
}
