import React, { useState } from "react";

const DevPre = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  if (false && process.env.NODE_ENV === "development") {
    return (
      <div
        style={{
          position: props.absolute ? "absolute" : "relative",
          maxWidth: 0,
          maxHeight: 0,
          ...props.containerStyle,
        }}
      >
        <pre
          style={{
            cursor: "pointer",
            position: "absolute",
            maxWidth: "none",
            ...props.style,
          }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? "+" : props.children}
        </pre>
      </div>
    );
  }
  return null;
};

export default DevPre;
