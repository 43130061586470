import "../styles/task-form.scss";

export default function TaskFormRadio({ label, value, name, onChange }) {
  return (
    <div className="task-form-radio">
      <label className="task-form-radio-label">
        <input
          type="radio"
          className="task-form-radio-input"
          name={name}
          value={value}
          onChange={(event) => {
            onChange(name, value);
            console.log(event.target.value);
          }}
        />
        <span className="task-form-radio-text">{label}</span>
      </label>
    </div>
  );
}
